import ManageAdminRepository from "../api";

class ManageAdminUseCase {
  constructor() {
    /**
     *  @type { ManageAdminRepository }
     */

    this.api = new ManageAdminRepository()
  }

  /**
   *  @param { string } page
   *  @param { string } perPage
   *  @returns { Promise<{data: any | null, error: string | null} }
   */

  async getAll(page, perPage, search) {
    const { data, error } = await this.api.getAll(page, perPage, search)
    return { data: data.data, error, pagination: data.pagination }
  }

  async get(id) {
    const { data, error } = await this.api.get(id)
    return { data, error }
  }

  async create(payload) {
    const { data, error } = await this.api.create(payload)
    return { data, error }
  }

  async delete(id) {
    const { data, error } = await this.api.delete(id)
    return { data, error }
  }

  async update(id, payload) {
    const { data, error } = await this.api.update(id, payload)
    return { data, error }
  }
}

export default new ManageAdminUseCase()
