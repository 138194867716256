<template>
  <div class="image-upload">
    <div v-if="!src && !ImageSrc" class="image-upload__component" @click="handleClick">
      <div class="image-upload__component__icon">
        <i class="nc-icon nc-cloud-upload-94"></i>
        <span>format harus .jpg/.png/.jpeg max size {{ maxSize }}MB </span>
        <input
          ref="file"
          v-bind="$attrs"
          accept="image/jpg, image/png, image/jpeg"
          type="file"
          style="display: none"
          @focus="$emit('focus', $event)"
          @change="onChange"
          @click="$emit('click', $event)"
        />
      </div>
      <div v-if="error" class="image-upload__component__error">
        <span>{{ errorMessage }} {{ maxSize }}MB</span>
      </div>
    </div>
    <div v-if="src || ImageSrc" class="image-upload__image">
      <div class="image-upload__image__item">
        <img :src="src || ImageSrc" alt="">
      </div>
      <div class="image-upload__image__close">
        <i class="nc-icon nc-simple-remove" @click="onClose"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageUpload",
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "gambar tidak boleh melebihi",
    },
    maxSize: {
      type: Number,
      default: 5,
    },
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      error: this.isError,
      errorMessageImage: this.errorMessage,
      ImageSrc: this.src,
    };
  },
  methods: {
    handleClick() {
      this.$refs.file.click();
    },
    onChange(event) {
      const maxSize = this.maxSize * 1024 * 1024;
      if (event.target.files[0].size >= maxSize) {
        this.error = true;
        this.errorMessageImage = `gambar tidak boleh melebihi ${this.maxSize}MB`
      } else {
        const url = URL.createObjectURL(event.target.files[0]);
        this.ImageSrc = url;
        this.$emit("value", [url, event.target.files[0]]);
      }
    },
    onClose() {
      this.ImageSrc = ''
      this.$props.src = ''
      this.$emit('close', '')
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/lbd/variables";

.image-upload {
  width: 100%;

  &__component {
    width: 400px;
    height: 200px;
    border: 2px solid $default-color-top;
    border-style: dashed;
    border-radius: 16px;
    cursor: pointer;

    &__icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      > i {
        font-size: 32px;
        margin-bottom: 8px;
        color: $dark-gray;
      }

      > span {
        font-size: 14px;
        color: $dark-gray;
      }
    }

    &__error {
      margin-top: 8px;
      color: $red-color-bottom;
    }
  }

  &__image {
    display: flex;
    align-items: center;

    &__item {
      margin-right: 46px;
      > img {
        width: 400px;
        height: 200px;
        object-fit: cover;
        border-radius: 16px;
        box-shadow: $box-shadow;
      }
    }

    &__close {
      /deep/.nc-icon {
        font-size: 18px;
        font-weight: bold;
        color: $black-color;
        cursor: pointer;
      }

    }

  }
}
</style>
