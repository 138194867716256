import { MapPagination } from '@/app/Mappers'
import { TimeMapper } from '@/app/utils'

export const MapEventList = (response) => {
    return {
        pagination: MapPagination(response.meta),
        data: response?.data?.map((item) => MapEventDetail(item))
    }
}


export const MapEventDetail = (response) => {
    return {
        id: response?.id,
        title: response?.title,
        slug: response?.slug,
        category: response?.category,
        dateOfEvent: response?.date_of_event,
        placeOfEvent: response?.place_of_event,
        timeOfEvent: response?.time_of_event,
        imageThumbnails: response?.image_thumbnails,
        images: response.images,
        linkRegister: response.url_register,
        description: response.description,
        highlight: response.highlight,
        createdAt: TimeMapper(response?.created_at),
        updatedAt: TimeMapper(response?.updated_at)
    }
        
}