<template>
	<div class="update-event">
		<Modal :showModal="showModal" :isConfirmation="true" :useHeader="false" width="28"
			@cancel="onModalActions(ENUM_ACTION.CANCEL)" @oke="onModalActions(ENUM_ACTION.OKE)">
			<div class="text-confirmation">
				<h4>{{ textConfirmation }}</h4>
			</div>
		</Modal>
		<Loader colorClass="l-gray" v-if="isLoading" />
		<div class="update-event__form">
			<div class="update-event__form__title">
				<label>Title<span>*</span></label>
				<BaseInput v-model="form.title" placeholder="Silahkan masukan title" />
			</div>

			<div class="update-event__form__title">
				<label>Highlight<span>*</span></label>
				<BaseInput v-model="form.highlight" placeholder="Silahkan masukan Highlight" />
			</div>

			<div class="update-event__form__title">
				<label>Kategori<span>*</span></label>
				<Dropdown
					placeholder="Select Category"
					:options="categories"
					:defaultValue="form.category"
					@input="(value) => form.category = value"
						/>
			</div>
			<div class="update-event__form__title">
				<label>Tempat Di Selenggarakan<span>*</span></label>
				<BaseInput v-model="form.placeOfEvent" placeholder="Silahkan masukan Tempat diselenggarakan" />
			</div>

			<div class="update-event__form__title">
				<label>Tanggal Di Selenggarakan<span>*</span></label>
				<div>
					<date-picker type="date" v-model="form.dateOfEvent" valueType="format"
						placeholder="Silahkan Masukan Tanggal" :clearable="false" :disabled-date="disabledBeforeTodayAndAfterAWeek"></date-picker>
				</div>
			</div>

			<div class="">
				<label>Waktu Di Selenggarakan<span>*</span></label>
				<div class="">
					<date-picker type="time" v-model="form.timeOfEventStart" valueType="format" format="HH:mm"
						placeholder="Silahkan Masukan Waktu" :clearable="false" @change="() => form.timeOfEventEnd = null"></date-picker>
					<span class="" style="margin: 0 12px;">
						sampai 
					</span>	
					<date-picker type="time" v-model="form.timeOfEventEnd" valueType="format" format="HH:mm"
						placeholder="Silahkan Masukan Waktu" :clearable="false" :disabled-time="notBeforeStartOClock" :disabled="!form.timeOfEventStart"></date-picker>
				</div>				
					</div>

			<div class="update-event__form__description">
				<label>Deskripsi <span>*</span></label>
				<VueEditor v-model="form.description" placeholder="Silahkan masukan content event anda" />
				<span v-if="!$v.form.description.minLength" class="noted" style="color: red !important;">Kurang dari 100
					karakter</span>
				<span v-else class="noted">Note: minimal 100 karakter</span>
			</div>

			<div class="update-event__form__title">
				<label>Gambar Thumbnail <span>*</span></label>
				<MultipleImage @value="onMultipleUpload" @close="onChangeUpload" :maxImages="1" :imageSrc="imageThumbnailSrc" />
			</div>

			<div class="update-article__form__image">
				<label>Gambar Utama <span>*</span></label>
				<ImageUpload :src="form.imageMain[0]?.url" @value="onImageUpload" @close="onClose" :maxSize="5" />
			</div>


			<div class="update-event__form__title">
				<label>Link Pendaftaran<span>*</span></label>
				<BaseInput v-model="form.linkRegister" placeholder="Silahkan masukan Link Pendaftaran" />
				<span v-if="!$v.form.linkRegister.url" class="noted" style="color: red !important;">Link tidak valid</span>
			</div>

			<div class="update-event__form__actions">
				<Button type="danger" text="Kembali" @click="onActions(ENUM_ACTION.BACK)" />
				<Button :isDisabled="$v.form.$invalid" type="info" text="Simpan" @click="onActions(ENUM_ACTION.SUBMIT)" />
			</div>
		</div>
	</div>
</template>

<script>
import { ENUM_ACTION } from '@/app/Constants'
import { required, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import MultipleImage from '@/components/MultipleImage/MultipleImage.vue'
import ManageEventUseCase from '../app/usecase'
import { VueEditor } from 'vue2-editor';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { url } from 'vuelidate/lib/validators'

export default {
	components: {
		MultipleImage,
		VueEditor,
		DatePicker
	},
	mixins: [validationMixin],
	data() {
		return {
			ENUM_ACTION,
			showModal: false,
			isSubmit: false,
			textConfirmation: '',
			isLoading: false,
      imageMainSrc: '',
			message: {
				success: 'event berhasil di buat',
				failed: 'event gagal di buat'
			},
			categories: [
			{label:'Psychological Test', value: 'Psychological Test'},
			{label: 'Workshop', value: 'Workshop'},
			{label: 'Program Promo', value: 'Program Promo'},
			{label: 'Webinar', value: 'Webinar'}
			],
			form: {
				title: '',
				description: '',
				highlight: '',
				category: null,
				imageThumbnail: [],
				imageMain: [],
				placeOfEvent: '',
				dateOfEvent: null,
				timeOfEventStart: null,
				timeOfEventEnd: null,
				linkRegister: ''
			}
		}
	},
  computed: {
    imageThumbnailSrc() {      
      return this.form.imageThumbnail?.map((item) => item?.url)
    }
  },
	methods: {
		notifyVue(verticalAlign, horizontalAlign, color, typeMessage) {
			this.$notifications.notify({
				message: this.message[typeMessage],
				icon: "nc-icon nc-app",
				horizontalAlign: horizontalAlign,
				verticalAlign: verticalAlign,
				type: color,
			});
		},
    async getEvent() {
      this.isLoading = true;
      const response = await ManageEventUseCase.get(this.$route.query.id);
      
      if (response && !response.error) {
        this.form = {
          ...response.data,
          imageThumbnail: response.data.imageThumbnails?.map((item) => ({id: item.id, url: item.url, file: null})),
          imageMain: response.data?.images?.map((item) => ({id: item.id, url: item?.url, file: null})),
          timeOfEventStart: response.data?.timeOfEvent?.split('-')[0],
          timeOfEventEnd: response?.data?.timeOfEvent?.split('-')[1]?.split(' ')[0],
        };
        this.imageMainSrc = response.data?.images?.length > 0 ? response.data.images[0]?.url : ''
      } else {
        this.notifyVue("top", "right", "danger", "failed");
      }

      this.isLoading = false;
    },
		onModalActions(type) {
			if (type === ENUM_ACTION.CANCEL) {
				this.showModal = false
			}

			if (type === ENUM_ACTION.OKE) {
				this.updateEvent(this.form, this.$route.query.id)
			}
		},
		onChange() { },
		onActions(type) {
			if (type === ENUM_ACTION.BACK) {
				this.$router.push('/management-event')
			}

			if (type === ENUM_ACTION.SUBMIT) {
				this.textConfirmation = 'Apakah data sudah benar ?'
				this.showModal = true
			}

		},
		onMultipleUpload(value) {
			this.form.imageThumbnail[0] = {
        ...this.form.imageThumbnail[0],
        url: value[0][0],
        file: value[1][0]
      }
		},
		onUploadPdf(value) {
			this.form.file = value[1]
		},
		async updateEvent(data, id) {
			this.isLoading = true
			const payload = new FormData()
			payload.append('title', data.title)
			payload.append('highlight', data.highlight)
			payload.append('category', data.category)
			payload.append('date_of_event', data.dateOfEvent)
			payload.append('time_of_event', data.timeOfEventStart + '-' + data.timeOfEventEnd + ' WIB')
			payload.append('place_of_event', data.placeOfEvent)
			payload.append('description', data.description)
      payload.append('url_register', data.linkRegister)
      
      if(data.imageMain?.length > 0) {
        data.imageMain?.map((item) => {
          if(item.file) {
            payload.append(`images_${item?.id}`, item.file)
          }
        })
      }

      if(data.imageThumbnail?.length > 0) {
        data.imageThumbnail?.map((item) => {
          if(item.file) {
            payload.append(`imageThumbnails_${item.id}`, item.file)
          }
        })
      }			
			
			const response = await ManageEventUseCase.edit(payload, id)
			if (response && !response.error) {
				this.notifyVue('top', 'right', 'success', 'success')
				this.$router.push('/management-event')
			} else {
				this.notifyVue('top', 'right', 'danger', 'failed')
			}

			this.showModal = false
			this.isLoading = false
		},
		onImageUpload(value) {
      
			this.form.imageMain[0] = {
        ...this.form.imageMain[0],
        url: value[0],
        file: value[1]
      }
		},
		onChangeUpload(value) {
			this.form.imageThumbnail[0] = {
        ...this.form.imageThumbnail[0],
        url: null,
        file: null
      }

		},
		disabledBeforeTodayAndAfterAWeek(date) {
			const today = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
			today.setHours(0, 0, 0, 0);

			return date < today
		},
		notBeforeStartOClock(date) {
			const hoursMoreThanStart = date.getHours() < Number(this.form.timeOfEventStart?.split(':')[0]);
			const minutsMoreThanStart = date.getMinutes() -1 < Number(this.form.timeOfEventStart?.split(':')[1]);
			
      		return (date.getHours() ===  Number(this.form.timeOfEventStart?.split(':')[0]) ? minutsMoreThanStart : false) || hoursMoreThanStart
    	},
		onClose(value) {
      if(this.form.imageMain?.length > 0) {
      		this.form.imageMain[0] = {
            ...this.form.imageMain[0],
            file: value
          }

        }
    	},
	},
  created() {
    this.getEvent()
  },
	validations() {
		return {
			form: {
				title: {
					required
				},
				category: {
					required
				},
				highlight: {
					required
				},
				dateOfEvent: {
					required
				},
				timeOfEventStart: {
					required
				},
				timeOfEventEnd: {
					required
				},
				linkRegister: {
					url,
					required
				},
				placeOfEvent: {
					required
				},
				description: {
					required,
					minLength: minLength(100)
				},
				imageThumbnail: {
					required,
					minLength: minLength(1)
				},
				imageMain: {
					required,
				},
			}
		}
	},
};
</script>

<style lang="scss" scoped>
@import '../../../assets/sass/lbd/variables';

.update-event {
	width: 90%;
	background-color: white;
	margin: auto;
	padding: 20px;

	&__form {
		display: flex;
		flex-direction: column;

		>div:first-child {
			margin-bottom: 12px;
		}

		>div {
			margin-bottom: 32px;

			>label {
				color: $text-base;
				font-weight: 600;

				span {
					color: $danger-states-color;
				}
			}
		}

		&__actions {
			display: flex;
			justify-content: flex-end;

			>div {
				margin-right: 12px;
			}
		}
	}
}

.text-confirmation {
	text-align: center;
	margin-bottom: 20px;
}

textarea {
	padding: 10px 20px;
	width: 100%;
	color: $text-base;
	border: 1px solid $light-gray;
	border-radius: 4px;

	&::placeholder {
		color: $light-gray;
	}
}

textarea:focus {
	outline: none;
	border: 1px solid $dark-gray;
}

.noted {
	font-size: 12px;
	color: #4449;
}

.update-event__form__discount {
	display: flex;
	align-items: start;

	.update-event__form__final-price {
		width: 100%;
	}
}
</style>
