<template>
  <div class="icon-arrow" @click="() => $router.back()">
    <IconArrow fill="#696969" />
    <span>Kembali</span>
  </div>
</template>

<script>
import IconArrow from '../../assets/icons/icon-arrow.vue'
  export default {
    name: 'BackComponent',
    components: { IconArrow }

  }
</script>

<style lang="scss" scoped>
.icon-arrow {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 16px;
  color: #696969;
  cursor: pointer;

  > span {
    margin-left: 6px;
  }
}
</style>
