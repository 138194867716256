import { render, staticRenderFns } from "./ManageArticle.vue?vue&type=template&id=2e622e17&scoped=true"
import script from "./ManageArticle.vue?vue&type=script&lang=js"
export * from "./ManageArticle.vue?vue&type=script&lang=js"
import style0 from "./ManageArticle.vue?vue&type=style&index=0&id=2e622e17&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e622e17",
  null
  
)

export default component.exports