import { validation, MONTH_NAMES } from "../Constants"

export const validationWithRegex = (value, type) => {
  return !value.match(validation[type])
}

export const TimeMapper = (string, withClock) => {
  const time = new Date(string)
  const date = time.getDate()
  const month = MONTH_NAMES[time.getMonth()]
  const year = time.getFullYear()
  const hours = time.getHours()
  const minutes = time.getMinutes()
  const second = time.getSeconds()

  if(withClock) {
    return `${date} ${month} ${year} ${hours}:${minutes < 10 ? '0' + minutes : minutes}:${second}`
  }
  return `${date} ${month} ${year}`
}

export const formatCurrancy = (value) => {
  let mod = String(value).length % 3 === 0 ? 3 : String(value).length % 3
  let fractions = String(value).slice(mod).split('')
  let result = []
  fractions.forEach((item, index) => {
    if(index % 3 === 0) {
      result.push('.')
    }
    result.push(item)
  })
  const nominal = String(value).split('').splice(0, mod)
  return `Rp${nominal.join('')}${result.join('')},-`
}

export const currencyDigit = (input, withDefault = 0) => {
  if (isNaN(input) || input === 0) {
    return withDefault
  }
  return input.toFixed().replace(/(\d)(?=(\d{3})+(,|$))/g, '$1.')
}

export const formatCurrencyToNumber = (currency) => {  
  if(typeof currency === 'number') {
    return
  } else if (currency === '') {
    return 0
  } else if (currency?.includes('Rp')) {    
    return Number(currency.match(/[0-9]/g)?.join(''))
  } else {
    return Number(currency?.replaceAll('.', ''))
  }
}

export const convertTransactionStatus = (value) => {
  switch (value) {
    case 'Waiting Verification':
      return 'Menunggu Verifikasi';
    case 'Processed':
      return 'Proses';
    case 'Rejected':
      return 'Tolak';
    case 'Cancel':
      return 'Gagal';
    case 'Done':
      return 'Setuju';
    default:
      return '';
  }
}
