import Cookies from "js-cookie";


export const tokenKey = `${process.env.NODE_ENV}_auth_login`

export const setCredentials = ({ token }) => {
  Cookies.set('type', 'super_admin')
  Cookies.set(tokenKey, token)
}

export const getToken = () => Cookies.get(tokenKey)

export const removeToken = () => Cookies.remove(tokenKey)
