<template>
    <div class="create-article">
      <Modal
        :showModal="showModal"
        :isConfirmation="true"
        :useHeader="false"
        width="28"
        @cancel="onModalActions(ENUM_ACTION.CANCEL)"
        @oke="onModalActions(ENUM_ACTION.OKE)"
      >
      <div class="text-confirmation">
        <h4>{{ textConfirmation }}</h4>
      </div>
      </Modal>
      <Loader colorClass="l-gray" v-if="isLoading" />
      <div class="create-article__form">
        <div class="create-article__form__title">
          <label>Title <span>*</span></label>
          <BaseInput v-model="form.title" @change="onChange" placeholder="Silahkan masukan title"/>
        </div>
        <div class="create-article__form__category">
          <label>Category <span>*</span></label>
          <BaseInput v-model="form.category" @change="onChange" placeholder="Silahkan masukan category"/>
        </div>
        <div class="create-article__form__image">
          <label>Gambar Artikel <span>*</span></label>
          <ImageUpload @value="onImageUpload" @close="onClose" :maxSize="10" :src="form.attachment.url" />
        </div>
        <div class="create-article__form__image">
          <label>Gambar Thumbnail <span>*</span></label>
          <ImageUpload @value="onImageUploadThumbnail" @close="onCloseThumbnail" :maxSize="2" :src="form.attachmentThumbnail.url" />
        </div>
        <div class="create-article__form__highlight">
          <label>highlight <span>*</span></label>
          <textarea style="padding: 12px;"name="" id="" cols="10" rows="3" v-model="form.highlight" @change="onChange" placeholder="Silahkan masukan highlight" :class="!$v.form.highlight.maxLength ? 'error' : ''" />
          <span class="noted">Note: Tidak boleh melebihi 200 karakter</span>
          <span v-if="!$v.form.highlight.maxLength" style="color: red; font-size: 10px;">Anda Melebihi 200 karakter</span>
        </div>
        <div class="create-article__form__content">
          <label>Content Artikel <span>*</span></label>
          <VueEditor
            v-model="form.article"
            placeholder="Silahkan masukan content artikel anda"
            @input="onChange"
          />
        </div>
        <div class="create-article__form__actions">
          <Button type="danger" text="Kembali" @click="onActions(ENUM_ACTION.BACK)" />
          <Button :isDisabled="$v.form.$invalid" type="info" text="Simpan" @click="onActions(ENUM_ACTION.SUBMIT)" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { VueEditor } from 'vue2-editor';
  import { ENUM_ACTION } from '@/app/Constants'
  import { required, minLength } from 'vuelidate/lib/validators'
  import { validationMixin } from 'vuelidate'
  import ManageArticleUseCase from '../app/usecase'
  import { maxLength } from 'vuelidate/lib/validators';
  
  export default {
    components: {
      VueEditor,  
    },
    mixins: [validationMixin],
    data() {
      return {
        ENUM_ACTION,
        showModal: false,
        isChange: false,
        isSubmit: false,
        textConfirmation: '',
        isLoading: false,
        message: {
          success: 'Artikel berhasil di buat',
          failed: 'Artikel gagal di buat'
        },
        form: {
          article: '',
          title: '',
          category: '',
          attachment: {
            id: null,
            file: null,
            url: null
          },
          highlight: '',
          attachmentThumbnail: {
            id: null,
            file: null,
            url: null
          }
        }
      }
    },
    created() {
    this.getArticle();
  },
    methods: {
      notifyVue(verticalAlign, horizontalAlign, color, typeMessage) {
        this.$notifications.notify({
          message: this.message[typeMessage],
          icon: "nc-icon nc-app",
          horizontalAlign: horizontalAlign,
          verticalAlign: verticalAlign,
          type: color,
        });
      },
      async createArticle() {
        this.isLoading = true
        const payload = new FormData()
        payload.append('title', this.form.title)
        payload.append('category', this.form.category)
        payload.append('description', this.form.article)
        payload.append('highlight', this.form.highlight)
        console.log('form', this.form);
        
        if(this.form.attachment?.file) {
            payload.append(`images_${this.form.attachment?.id}`, this.form.attachment.file)
        }
        if(this.form.attachmentThumbnail?.file) {
            payload.append(`imageThumbnails_${this.form.attachmentThumbnail.id}`, this.form.attachmentThumbnail.file)
        }
  
        const response = await ManageArticleUseCase.update(this.$route.query.id, payload)
        if(response && response.data) {
          this.notifyVue('top', 'right', 'success', 'success')
          this.$router.push('/management-article')
        } else {
          this.notifyVue('top', 'right', 'danger', 'failed')
        }
        this.isLoading = false
  
        this.showModal = false
      },
      onImageUpload(value) {
        this.form.attachment.file = value[1]
        this.isChange = true
      },
      onImageUploadThumbnail(value) {
        this.form.attachmentThumbnail.file = value[1]
        this.isChange = true
      },
      onActions(type) {
        if(type === ENUM_ACTION.BACK) {
          if(!this.isChange) {
            this.$router.back();
          } else {
            this.isSubmit = false
            this.textConfirmation = 'Apakah anda yakin menghapus perubahan ?',
            this.showModal = true
          }
        }
  
        if(type === ENUM_ACTION.SUBMIT) {
          this.isSubmit = true
          this.textConfirmation = 'Apakah data sudah benar ?'
          this.showModal = true
        }
      },
      onClose(value) {
        console.log(value);
        
        this.form.attachment.file = value
      },
      onCloseThumbnail(value) {
        this.form.attachmentThumbnail.file = value
      },
      onChange() {
        this.isChange = true
      },
      onModalActions(type) {
        if(type === ENUM_ACTION.CANCEL) {
          this.showModal = false
        }
  
        if(type === ENUM_ACTION.OKE) {
          if(!this.isSubmit) {
            this.$router.back();
          } else {
            this.createArticle()
          }
          this.showModal = false
        }
      },
      async getArticle() {
        this.isLoading = true;
        const response = await ManageArticleUseCase.get(this.$route.query.id);
        if (response && !response.error) {
            console.log('resp', response);
            
            this.form = {
                article: response.data?.description,
                title: response.data?.title,
                category: response.data?.category,
                attachment: {
                    id: response?.data?.attachment[0]?.id,
                    file: null,
                    url: response?.data?.attachment[0]?.url
                },
                highlight: response.data?.highlight,
                attachmentThumbnail: {
                    id: response?.data?.attachmentThumbnails[0]?.id,
                    file: null,
                    url: response?.data?.attachmentThumbnails[0]?.url
                }
        }
        } else {
          this.notifyVue('top', 'right', 'danger', 'failed')
        }
  
        this.isLoading = false;
      },
    },
    validations: {
      form: {
        article: {
          required,
          minLength: minLength(1)
        },
        title: {
          required,
          minLength: minLength(1)
        },
        attachment: {
          required
        },
        attachmentThumbnail: {
          required
        },
        highlight: {
          required,
          maxLength: maxLength(200)
        },
        category: {
            required
        }
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import '../../../assets/sass/lbd/variables';
  
  .create-article {
    width: 90%;
    background-color: white;
    margin: auto;
    padding: 20px;
  
    &__form {
      display: flex;
      flex-direction: column;
  
      > div:first-child {
        margin-bottom: 12px;
      }
  
      > div {
        margin-bottom: 32px;
  
        > label {
          color: $text-base;
          font-weight: 600;
  
          span {
            color: $danger-states-color;
          }
        }
      }
  
      &__actions {
        display: flex;
        justify-content: flex-end;
  
        > div {
          margin-right: 12px;
        }
      }
      &__highlight {
        display: flex;
        flex-direction: column
      }
    }
  }
  
  .text-confirmation {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .noted {
    font-size: 12px;
    color: #4449;
  }
  textarea {
    color: $text-base;
    border: 1px solid $light-gray;
    border-radius: 4px;
  }
  
  textarea.error {
    border: 1px solid red !important
  }
  
  textarea:focus {
    outline: none;
    border: 1px solid $dark-gray;
  }
  </style>
  