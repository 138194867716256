import ManageBroadcastRepository from "../api";

class ManageBroadcastUseCase {
  constructor() {
    /**
     * @type { ManageBroadcastRepository }
     */

    this.api = new ManageBroadcastRepository
  }

  /**
   *  @param { string } page
   *  @param { string } perPage
   *  @returns { Promise<{data: any | null, error: string | null} }
   */

    async getAll(page, perPage, search) {
      const { data, error } = await this.api.getAll(page, perPage, search)
      return { data: data, error, pagination: data.pagination }
    }
}

export default new ManageBroadcastUseCase()
