<template>
  <div class="button">
    <button
      :disabled="isDisabled"
      :class="[
      'button__component',
      isDisabled ?  'disabled' : type,
      className,
      { 'custom-padding' : customPadding }]"
      @click="$emit('click')"
    >
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {
      type: String,
      default: 'button'
    },
    type: {
      type: String,
      default: 'info',
      require: true,
      validator: () => ['info', 'success', 'danger', 'warning']
    },
    customPadding: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/sass/lbd/variables';
.button {

  &__component {
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    font-size: 18px;
    display: inline-block;
    font-weight: 600;
    color: white;
    border: none;
    transition: transform 0.2s;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;

    &:not(.custom-padding) {
      padding: 8px 42px;
    }

    &:hover {
      transform: scale(105%, 105%);
    }

    &:active {
      transform: scale(97%, 90%);
    }
  }
}

button.info {
  background-color: #1365b3;
}

button.warning {
  background-color: #f1dc18;
}

button.danger {
  background-color: #d11313;
}

button.success {
  background-color: #3ca811;
}

button.disabled {
  background-color: #4444;
}
</style>
