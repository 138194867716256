export const validation = {
  EMAIL: /^([A-z0-9-_.]+)@([A-z0-9-_.]+)\.([A-z]{2,5})$/i,
  NAME: /^[a-zA-Z\'’.,\s]+$/i,
  ADDRESS : /^[A-Za-z0-9'\.\-\s\,/#_()\[\]]+$/i
}

export const ERROR_MESSAGE = {
  '2': 'Tidak dapat melakukan update dengan data sendiri',
  '3' : 'Email anda sudah terpakai',
  '4' : 'Nomor handphone sudah terpakai',
}

export const MONTH_NAMES = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
"Juli", "Agustus", "September", "Oktober", "November", "Desember"
]

export const ENUM_ACTION = {
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  DETAIL: 'DETAIL',
  BACK: 'BACK',
  OKE: 'OKE',
  CANCEL: 'CANCEL',
  SUBMIT: 'SUBMIT',
  NEXT: 'NEXT',
  PREV: 'PREVIOUS'
}
