<template>
  <div
    class="loader">
    <div
      class="lds-ellipsis"
      :class="colorClass"
      :style="{ left: positionLeft, top: '20%'}"
    >
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
</template>

<script>
// source from https://loading.io/css/
export default {
  name: "Loader",
  props: {
    colorClass: {
      type: String,
      default: "l-white",
    },
    positionLeft: {
      type: String,
      default: "44%",
    },
  },
};
</script>

<style scoped>

.loader {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
}
.l-white div {
  background: #fff;
}

.l-gray div {
  background: #4d4d4d;
}

.lds-ellipsis.l-gray {
  background-color: white;
}

.lds-ellipsis.l-white {
  background-color: grey;
}
.lds-ellipsis {
  position: absolute;
  padding: 40px 100px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 68px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 68px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 92px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 116px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
