import CMSInternalAPI from "@/app/BaseAxios";
import { MapUsers, MapUser } from "../mappers";

export default class ManageUserRepository extends CMSInternalAPI {
  constructor() {
    super()

    /** @type { string } */
    this.endpoint = 'v1/admin/management-bank-account'
  }

  /**
   *
   * @param { string } page
   * @param { string } perPage
   * @param { string } search
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async getAll(search = '') {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.get(`${this.endpoint}/list`)
      
      if(response && response.status === 200) {
        return { data: MapUsers(response.data), error: null }
      }
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }

  async get(id) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.get(`${this.endpoint}/detail/${id}`)
      if(response && response.status === 200) {
        return { data: MapUser(response.data.data), error: null }
      }
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }

  async create(payload) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.post(`${this.endpoint}/create`, payload)
      if(response && response.status === 201) {
        return { data: response.data, error: null }
      }
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }

  async delete(id) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.delete(`${this.endpoint}/delete/${id}`)
      if(response && response.status === 200) {
        return { data: response.data , error: null}
      }
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }

  async update(id, payload) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.put(`${this.endpoint}/update/${id}`, payload)
      if(response && response.status === 200) {
        return { data: response.data, error: null }
      }
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
}
