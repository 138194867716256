<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <div class="header">
                <div class="header__title">
                  <h4 class="card-title">Management User</h4>
                  <p class="card-category">Management akun user</p>
                </div>
                <Button text="Tambah User" @click="addUser" />
              </div>
            </template>
            <div class="form-filter">
              <BaseInput width="50" v-model="search" placeholder="Cari Berdasarkan nama" />
              <Button type="info" text="Cari" @click="onSearch" />
            </div>
            <div class="table-responsive">
              <l-table
                class="table-hover"
                :columns="table.columns"
                :data="table.data"
                @click="(e) => handleClick(e)"
              >
              </l-table>
            </div>
          </card>
        </div>
        <Loader v-show="isLoading" colorClass="l-gray" />
        <!-- open Modal -->
        <Modal
          :showModal="showModal"
          :useHeader="true"
          :isConfirmation="true"
          textHeader="Tambah User Form"
          @cancel="onActionsModal('cancel')"
          @oke="onActionsModal('oke')"
          textButton="Simpan"
          top="10"
        >
          <template>
            <div class="modal-user">
              <label for="nama">Nama</label>
              <BaseInput
                placeholder="Masukan nama"
                type="text"
                v-model="form.name"
                :errorMessage="error.name"
                required
              />
              <label for="email">Email</label>
              <BaseInput
                placeholder="Masukan email"
                type="email"
                v-model="form.email"
              />
              <label for="phone">Nomor Handphone</label>
              <BaseInput
                addonLeftIcon="+62"
                placeholder="Masukan nomor handphone"
                type="number"
                :value="form.phone"
                @input="(value) => onInputPhone(value)"
                required
              />
              <label for="address">Alamat</label>
              <BaseInput
                placeholder="Masukan alamat"
                type="text"
                v-model="form.address"
                required
              />
              <label for="password">Password</label>
              <BaseInput
                autocomplete="off"
                :type="typePassword"
                placeholder="Masukan password"
                addonLeftIcon="nc-icon nc-key-25"
                addonRightIcon="nc-icon nc-tap-01"
                v-model="form.password"
                @showHide="togglePassword"
                @focus="onFocus('focus')"
                @blur="onFocus('blur')"
                required
              />
            </div>
          </template>
        </Modal>
      </div>
      <Pagination
        :page="pagination.page"
        @prev="onClick('prev')"
        @next="onClick('next')"
      />
    </div>
  </div>
</template>

<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import Button from "@/components/Button/Button.vue";
import ManageUserUseCase from './app/usecase'
import { MapUser, MapPayloadUser } from './app/mappers'
import { TimeMapper } from '@/app/utils'
import BaseInput from "@/components/Inputs/BaseInput.vue";
import Modal from '@/components/Modal/Modal.vue'
import { validationWithRegex } from "@/app/utils";
import Loader from '@/components/Loader/Loader.vue'
import Pagination from "@/components/Pagination/Pagination.vue";

export default {
  components: {
    LTable,
    Card,
    Button,
    BaseInput,
    Modal,
    Loader,
    Pagination
  },
  data() {
    return {
      table: {
        columns: [
          { label: 'Nama', value: 'name' },
          { label: 'Email', value: 'email' },
          { label: 'Nomor Handphone', value: 'phone' },
          { label: 'Dibuat', value: 'createdAt' }
        ],
        data: []
      },
      form: {
        name: "",
        email: "",
        phone: "",
        address: "",
        password: "",
      },
      isLoading: false,
      showModal: false,
      type: 'text',
      errorMessage: {
        empty: "Data anda tidak boleh kosong",
        email: "Email anda tidak valid",
        name: "nama tidak boleh angka",
        password: "Password harus lebih dari 8 huruf",
        phone: "Nomor handphone setidak nya 9 angka sampai 15 angka",
        address: 'Pastikan alamat diisi dengan benar',
        response: 'Gagal membuat data user'
      },
      error: [],
      search: '',
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1
      }
    };
  },
  computed: {
    typePassword() {
      return this.type;
    },
  },
  methods: {
    handleClick(value) {
      this.$router.push(`${this.$route.path}/${value.name}?id=${value.id}`);
    },
    addUser() {
      this.showModal = true
    },
    notifyVue(verticalAlign, horizontalAlign, color, item) {
      this.$notifications.notify({
        message: this.errorMessage[item],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    async getAllUsers() {
      this.isLoading = true
      const response = await ManageUserUseCase.getAll(this.pagination.page, this.pagination.perPage, this.search)
      if(response && !response.error) {
        this.table = {
          ...this.table,
          data: response.data.data.map((value) => {
            const user = MapUser(value)
            return {
              id: user.id,
              name: user.fullName,
              email: user.email,
              phone: user.phoneNumber,
              role: user.role,
              createdAt: TimeMapper(user.createdAt),
            }
          })
        }
        this.pagination = response.pagination
      } else {
        this.$notifications.notify({
          message: 'Gagal memuat data',
          icon: "nc-icon nc-app",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger',
        })
      }
      this.isLoading = false
    },
    async createUser(payload) {
      this.isLoading = true;
      if (this.validation()) {
        const response = await ManageUserUseCase.create(
          MapPayloadUser(payload)
        );
        if (response && !response.error) {
          this.errorMessage = {
            ...this.errorMessage,
            create: "Akun user berhasil di tambahkan",
          };
          this.notifyVue("top", "right", "success", "create");
          this.getAllUsers(this.pagination.page, this.pagination.perPage);
          this.showModal = false;
        } else {
          if (!Boolean(this.error.indexOf("create") + 1)) {
            this.error.push("create");
          }

          if (response.error.message.includes("Email")) {
            this.errorMessage = {
              ...this.errorMessage,
              create: "Email anda sudah terpakai",
            };
          }

          if (response.error.message.includes("Phonenumber")) {
            this.errorMessage = {
              ...this.errorMessage,
              create: "Nomor Handphone sudah terpakai",
            };
          }

          if(typeof response.error ===  'string') {
            this.notifyVue("top", "right", "danger", response);
          }

          this.error.map((item) => {
            this.notifyVue("top", "right", "danger", item);
          });
        }
      } else {
        this.error.map((item) => {
          this.notifyVue("top", "right", "danger", item);
        });
      }

      this.isLoading = false;
    },
    onActionsModal(type) {
      if (type === "cancel") {
        this.showModal = false;
      }

      if (type === "oke") {
        this.createUser(this.form);
      }
    },
    onInputPhone(value) {
      const el = document.querySelector('input[type="number"]');
      if (this.form.phone.length < 1 && value !== "8") {
        el.value = "";
        return;
      } else {
        if (value.length > 15) {
          el.value = this.form.phone;
          return;
        } else {
          return (this.form.phone = value);
        }
      }
    },
    onFocus(type) {
      if (type === "blur" && !this.form.password) {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    togglePassword() {
      if (this.type === "password") this.type = "text";
      else this.type = "password";
    },
    validation() {
      this.error = [];
      let result = false;
      const everyFill = !!(
        this.form.name &&
        this.form.address &&
        this.form.email &&
        this.form.password &&
        this.form.phone
      );

      if (everyFill) {
        if (validationWithRegex(this.form.email, "EMAIL")) {
          if (!Boolean(this.error.indexOf("email") + 1)) {
            this.error.push("email");
          }
        }

        if (validationWithRegex(this.form.name, "NAME")) {
          if (!Boolean(this.error.indexOf("name") + 1)) {
            this.error.push("name");
          }
        }

        if (this.form.password.length < 7) {
          if (!Boolean(this.error.indexOf("password") + 1)) {
            this.error.push("password");
          }
        }

        if (this.form.phone.length < 9 || this.form.phone.length > 15) {
          if (!Boolean(this.error.indexOf("phone") + 1)) {
            this.error.push("phone");
          }
        }

        if (validationWithRegex(this.form.address, 'ADDRESS')) {
          if(!Boolean(this.error.indexOf('address') + 1)) {
            this.error.push('address')
          }
        }

      } else {
        if (!Boolean(this.error.indexOf("empty") + 1)) {
          this.error.push("empty");
        }
      }

      if(this.error.length === 0) {
        result = true
      }
      return result;
    },
    onClick(type) {
      if (type === "prev" && this.pagination.page !== 1) {
        this.pagination.page--;
      }

      if (type === "next" && this.pagination.page !== this.pagination.totalPage) {
        this.pagination.page++;
      }

      this.getAllUsers()
    },
    onSearch() {
      this.pagination.page = 1
      this.getAllUsers()
    }
  },
  created() {
    this.getAllUsers()
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.form-filter {
  display: flex;

  > div:first-child {
    margin-right: 20px;
  }
}
</style>
