import ManageArticleRepository from "../api";

class ManageArticleUseCase {
  constructor() {
    /**
     *  @type { ManageArticleRepository }
     */

    this.api = new ManageArticleRepository()
  }

  /**
   *  @param { string } page
   *  @param { string } perPage
   *  @returns { Promise<{data: any | null, error: string | null} }
   */

    async getAll(page, perPage, search) {
      const { data, error } = await this.api.getAll(page, perPage, search)
      return { data: data.data, error, pagination: data.pagination }
    }

  /**
   *  @param { string } id
   *  @returns { Promise<{data: any | null, error: string | null} }
   */
  async get(id) {
    const { data, error } = await this.api.get(id)
    return { data: data, error }
  }

    /**
   *  @param { string } id
   *  @returns { Promise<{data: any | null, error: string | null} }
   */
    async create(id) {
      const { data, error } = await this.api.create(id)
      return { data: data, error }
    }

  /**
   *  @param { string } id
   *  @param { object } payload
   *  @returns { Promise<{data: any | null, error: string | null} }
   */
  async update(id, payload) {
    const { data, error } = await this.api.update(id, payload)
    return { data: data, error }
  }

  /**
   *  @param { string } id
   *  @returns { Promise<{data: any | null, error: string | null} }
   */
  async delete(id) {
    const { data, error } = await this.api.delete(id)
    return { data: data, error }
  }
}

export default new ManageArticleUseCase()
