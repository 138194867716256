import { render, staticRenderFns } from "./ManageProgramDetail.vue?vue&type=template&id=a155e6ac&scoped=true"
import script from "./ManageProgramDetail.vue?vue&type=script&lang=js"
export * from "./ManageProgramDetail.vue?vue&type=script&lang=js"
import style0 from "./ManageProgramDetail.vue?vue&type=style&index=0&id=a155e6ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a155e6ac",
  null
  
)

export default component.exports