import CMSInternalAPI from "@/app/BaseAxios";

import { 
  MapGetList, 
  MapSppDueDates,
  MapPacketProgram,
  MapStudenWithParent, 
  MapProgramByStudent, 
} from "../mappers";

export default class ManageSppPaymentRepository extends CMSInternalAPI {
  constructor() {
    super()

    /** @type { string } */
    this.endpoint = 'v1/admin/management-spp';
    this.endpointManagementPacket = 'v1/admin/management-packet';
    this.endpointManagementSppDueDate = 'v1/admin/management-spp/due-date';
  }

  /**
   *
   * @param { string } page
   * @param { string } perPage
   * @param { string } search
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async getAll(page = '1', perPage = '10', search = '', status = '', orderType = '') {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      let url = `${this.endpoint}/list?page=${page}&limit=${perPage}&student_full_name=${search}`;
      
      if (status) {
        url += `&transaction_status=${status}`;
      }
      
      if (orderType) {
        url += `&order_by=created_at&order_type=${orderType}`;
      }
      
      const response = await this.$axios.get(url);
      
      if (response && response.status === 200) {
        return { data: MapGetList(response.data), error: null }
      }
      
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
  
  async getStudenWithParents(search = '') {
    try {
      const response = await this.$axios.get(`${this.endpoint}/student/list?student_full_name=${search}`)
      
      if (response && response.status === 200) {
        return { data: MapStudenWithParent(response.data), error: null }
      }
      
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
  
  async getProgramByStudentId(id = null) {
    try {
      const response = await this.$axios.get(`${this.endpoint}/program/list?student_id=${id}`)
      if (response && response.status === 200) {
        return { data: MapProgramByStudent(response.data), error: null }
      }
      
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
  
  async getPacketProgram(programId, programType) {
    try {
      const response = await this.$axios.get(`${this.endpointManagementPacket}/list?program_id=${programId}&program_type=${programType}`)
      if (response && response.status === 200) {
        return { data: MapPacketProgram(response.data), error: null }
      }
      
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }

  async create(payload) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.post(`${this.endpoint}/submit`, payload)
      
      if (response && response.status === 201) {
        return { data: response.data, error: null }
      }
      
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
  
  async getSppDueDate() {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.get(`${this.endpointManagementSppDueDate}/list`);
      
      if (response && response.status === 200) {
        return { data: MapSppDueDates(response.data), error: null }
      }
      
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
  
  async createSppDueDate(payload) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.post(`${this.endpointManagementSppDueDate}/create`, payload)
      
      if (response && response.status === 201) {
        return { data: response.data, error: null }
      }
      
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
  
  async updateSppDueDate(id, payload) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.put(`${this.endpointManagementSppDueDate}/update/${id}`, payload)
      
      if (response && response.status === 200) {
        return { data: response.data, error: null }
      }
      
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
}
