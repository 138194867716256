<template>
  <div class="edit-product">
    <Modal
      :showModal="showModal"
      :isConfirmation="true"
      :useHeader="false"
      width="28"
      @cancel="onModalActions(ENUM_ACTION.CANCEL)"
      @oke="onModalActions(ENUM_ACTION.OKE)"
    >
      <div class="text-confirmation">
        <h4>{{ textConfirmation }}</h4>
      </div>
    </Modal>
    <Loader colorClass="l-gray" v-if="isLoading" />
    <div class="edit-product__form">
      <div class="edit-product__form__title">
        <label>Nama Produk<span>*</span></label>
        <BaseInput
          v-model="form.name"
          placeholder="Silahkan masukan nama produk"
        />
      </div>
      <div class="create-product__form__title">
        <label>Tipe Produk<span>*</span></label>
        <Dropdown
          :defaultValue="form.type"
          placeholder="Select Type Product"
          :options="productType"
          disabled
          @input="(value) => handleProductType(value)"
        />
      </div>
      <div class="edit-product__form__title">
        <label>Harga <span>*</span></label>
        <BaseInput
          type="currency"
          @input="handlePrice"
          :value="form.price"
          placeholder="Silahkan masukan harga"
        />
      </div>
      <div class="create-product__form__discount">
        <div class="create-product__form__percentage">
          <label>Persentase Diskon (%)</label>
          <BaseInput
            type="number"
            @input="handlePercentage"
            :value="form.percentageDiscount"
            placeholder="%"
            width="80"
          />
          <span class="noted">Note: Tidak boleh melebihi 100%</span>
        </div>
        <div class="create-product__form__final-price">
          <label>Harga Final</label>
          <BaseInput
            type="currency"
            disabled
            v-model="form.finalPrice"
            placeholder="-"
          />
        </div>
      </div>
      <div class="edit-product__form__description">
        <label>Deskripsi <span>*</span></label>
        <VueEditor
         v-model="form.description"
         placeholder="Silahkan masukan content product anda"
       />
        <span class="noted">Note: minimal 20 karakter</span>
      </div>
      <div class="edit-product__form__title">
        <label>Gambar Produk <span>*</span></label>
        <MultipleImage :imageSrc="imageSrc" @value="onMultipleUpload" @close="onCloseImage" :maxImages="maxImages"  />
      </div>
      <div v-if="form.type === 'digital'" class="edit-product__form__title">
        <label>Dokumen <span>*</span></label>
        <FileUpload
          :maxSize="5"
          :url="this.form.file[0]?.url"
          :name="
            this.form.file?.length > 0
              ? formattedNameFile(this.form.file[0].path)
              : ''
          "
          @value="onUploadPdf"
        />
      </div>
      <div class="edit-product__form__actions">
        <Button
          type="danger"
          text="Kembali"
          @click="onActions(ENUM_ACTION.BACK)"
        />
        <Button
          :isDisabled="$v.form.$invalid"
          type="info"
          text="Simpan"
          @click="onActions(ENUM_ACTION.SUBMIT)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ENUM_ACTION } from "@/app/Constants";
import { required, minLength, requiredIf } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import MultipleImage from "@/components/MultipleImage/MultipleImage.vue";
import ManageProductUseCase from "../app/usecase";
import { currencyDigit, formatCurrencyToNumber } from "@/app/utils";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    MultipleImage,
    VueEditor
  },
  mixins: [validationMixin],
  data() {
    return {
      ENUM_ACTION,
      showModal: false,
      isSubmit: false,
      textConfirmation: "",
      isLoading: false,
      maxImages: 0,
      imagesIdOnClose: [],
      productType: [
        {label: 'Digital', value: 'digital'},
        {label: 'Material', value: 'material'}
      ],
      message: {
        success: "Product berhasil di buat",
        failed: "Product gagal di buat",
      },
      form: {
        name: '',
        description: '',
        type: '',
        attachment: [],
        file: null,
        price: '',
        finalPrice: '',
        percentageDiscount: null,
        attachmentId: [],
        fileId: null
      },
    };
  },
  computed: {
    imageSrc() {      
      return this.form.attachment[0]
    }
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, typeMessage) {
      this.$notifications.notify({
        message: this.message[typeMessage],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    onModalActions(type) {
      if (type === ENUM_ACTION.CANCEL) {
        this.showModal = false;
      }

      if (type === ENUM_ACTION.OKE) {
        this.editProduct(this.form, this.$route.query.id);
      }
    },
    onChange() {},
    onActions(type) {
      if (type === ENUM_ACTION.BACK) {
        this.$router.push("/management-product");
      }

      if (type === ENUM_ACTION.SUBMIT) {
        this.textConfirmation = "Apakah data sudah benar ?";
        this.showModal = true;
      }
    },
    onMultipleUpload(value) {      
      this.form.attachment = value;
    },
    onUploadPdf(value) {
      this.form.file = value[1];
    },
    async getProduct() {
      this.isLoading = true;
      const response = await ManageProductUseCase.get(this.$route.query.id);
      if (response && !response.error) {
        const {
          attachment,
          description,
          files,
          type,
          name,
          price,
          finalPrice,
          discountPercentage,
        } = response.data;
        this.maxImages = attachment?.length
        this.form = {
          attachment: [attachment.map((item) => item.url), Array.from({length: attachment.length}).map(() => null)],
          description,
          type,
          file: files,
          name,
          price: currencyDigit(formatCurrencyToNumber(price)),
          fileId: files.map((item) => item.id),
          attachmentId: attachment.map((item) => { return {id: item.id, url: item.url} }),
          finalPrice: currencyDigit(formatCurrencyToNumber(finalPrice)),
          percentageDiscount: discountPercentage,
          fileId: files[0]?.id
        };
      } else {
        this.notifyVue("top", "right", "danger", "failed");
      }
      this.isLoading = false;
    },
    async editProduct(data, id) {
      this.isLoading = true;
      const payload = new FormData();
      const price = formatCurrencyToNumber(data.price)
      const finalPrice = formatCurrencyToNumber(data.finalPrice)
      this.form.attachment[1]?.forEach((item, index) => {
        if(item && this.imagesIdOnClose[index]) {
          payload.append(`images_${this.imagesIdOnClose[index]}`,item);
        }
      })
      payload.append("name", data.name);
      payload.append("initiate_price", price);
      payload.append("final_price", finalPrice);
      payload.append("type", data.type);
      payload.append('discount_percentage', data.percentageDiscount)
      payload.append("description", data.description);
      if(data.type === 'digital' && !this.form.file[0]?.id) {
        payload.append(`file_${data.fileId[index]}`, this.form.files);
      }
      
      const response = await ManageProductUseCase.edit(payload, id);
      if (response && !response.error) {
        this.notifyVue("top", "right", "success", "success");
        this.$router.push("/management-product");
      } else {
        this.notifyVue("top", "right", "danger", "failed");
      }

      this.showModal = false;
      this.isLoading = false;
    },
    formattedNameFile(string) {      
      return string.replaceAll("/", " ").split(" ").pop();
    },
    handlePercentage(value) {
      if(Number(value) > 100 || Number(value) < 0) return

      this.form.percentageDiscount = Number(value);
      const price =
        this.form.price ? this.form.price?.replaceAll(".", "") : "";

      this.form.finalPrice = currencyDigit(
        price - (Number(price) / 100) * value
      );
    },
    handlePrice(value) {
      this.form.price = value
      const price = !value ? '' : value?.replaceAll('.', '')

      this.form.finalPrice = currencyDigit(
        Number(price) - (Number(price) / 100) * this.form.percentageDiscount
      );
    },
    handleProductType(value) {
      this.form.type = value 
    },
    onCloseImage(value) {
      let findId = null
      this.form.attachmentId?.forEach((item, index) => {                
        if(!value[0]?.includes(item?.url)) {
          findId = item?.id
        }
      })
      if(findId && !this.imagesIdOnClose.includes(findId)) {
        this.imagesIdOnClose = [...this.imagesIdOnClose, findId]
      }
      
      this.form.attachment = value
      
    }
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        description: {
          required,
          minLength: minLength(20)
        },
        attachment: {
          required,
          minLength: minLength(1)
        },
        file: {
          required: requiredIf(() => this.form.type === 'digital')
        }
      }
    }
  },
  created() {
    this.getProduct();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/lbd/variables";

.edit-product {
  width: 90%;
  background-color: white;
  margin: auto;
  padding: 20px;

  &__form {
    display: flex;
    flex-direction: column;

    > div:first-child {
      margin-bottom: 12px;
    }

    > div {
      margin-bottom: 32px;

      > label {
        color: $text-base;
        font-weight: 600;

        span {
          color: $danger-states-color;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      > div {
        margin-right: 12px;
      }
    }
  }
}

.text-confirmation {
  text-align: center;
  margin-bottom: 20px;
}

textarea {
  padding: 10px 20px;
  width: 100%;
  color: $text-base;
  border: 1px solid $light-gray;
  border-radius: 4px;

  &::placeholder {
    color: $light-gray;
  }
}

textarea:focus {
  outline: none;
  border: 1px solid $dark-gray;
}
.noted {
  font-size: 12px;
  color: #4449;
}

.create-product__form__discount {
  display: flex;
  align-items: start;

  .create-product__form__final-price {
    width: 100%;
  }
}
</style>
