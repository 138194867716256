import { MapPagination } from '@/app/Mappers'

export const MapStudent = (payload) => {
  return {
    id: payload.id,
    fullName: payload.full_name,
    gender: payload.gender,
    dob: payload.dob,
    email: payload.user_email,
  }
}

export const MapStudents = (payload) => {
  return {
    pagination: MapPagination(payload.meta),
    data: payload.data
  }
}

export const MapDetailStudent = (payload) => {
  return {
    fullName: payload.full_name || '',
    gender: payload.gender || '',
    placeOfBirth: payload.place_of_birth || '',
    dob: payload.dob || '',
    religion: payload.religion || '',
    address: payload.address || '',
    previousSchool: payload.previous_school || '',
    isDisability: payload.is_disability,
  };
};


export const MapPayloadUser = (payload) => {
  return {
    full_name: payload.fullName,
    gender: payload.gender,
    place_of_birth: payload.placeOfBirth,
    dob: payload.dob,
    religion: payload.religion,
    address: payload.address,
    previous_school: payload.previousSchool,
    is_disability: payload.isDisability,
  }
}
