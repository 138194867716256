import { MapPagination } from '@/app/Mappers'

export const MapTransaction = (payload) => {
  return {
    id: payload.id,
    name: payload.name,
    userId: payload.user_id,
    email: payload.email,
    invoiceNumber: payload.invoice_number,
    status: payload.status,
    model: payload.model,
    sppDetail: payload.spp_detail,
    programDetail: payload.program_detail,
    total: payload.grand_total_price,
    createdAt: payload.created_at,
    updatedAt: payload.updated_at
  }
}

export const MapTransactions = (payload) => {
  return {
    pagination: MapPagination(payload.meta),
    data: payload.data
  }
}
