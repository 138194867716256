<template>
  <div class="file-upload">
    <div
      class="file-upload__component"
      :style="`width: ${width};`"
      @click="handleClick"
    >
    <span v-if="nameValue" style="color: #4d4d4d; word-break: break-all;">{{ nameValue }}</span>
    <span v-else style="color: #e3e3e3;">{{ placeholder }}</span>
    <div class="upload__component__input">
      <input
          ref="file"
          v-bind="$attrs"
          accept=".pdf"
          type="file"
          style="display: none"
          @focus="$emit('focus', $event)"
          @change="onChange"
          @click="(event) => {
            event.target.value = ''
            $emit('click', event)
          }"
        />
      </div>
    </div> 
    <Button
    v-if="previewUrl"
      text="Preview"
      type="success"
      customPadding
      className="custom-padding"      
      @click="handleClickPreview"
    />
    <div v-if="error" class="file-upload__error">
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "FileUpload",
  props: {
    width: {
      type: String,
      default: "50%",
    },
    maxSize: {
      type: Number,
      default: 1,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: 'Document tidak boleh melebihi 5MB'
    },
    placeholder: {
      type: String,
      default: `format harus .jpg/.png/.jpeg max size 5MB`
    },
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileName: "",
      fileUrl: '',
      error: this.isError,
      errorMessageDocument: ''
    };
  },
  computed: {
    nameValue() {
      if(this.fileName == '') {
        return this.name
      }
      return this.fileName
    },
    previewUrl() {
      if(!this.url) {
        return this.fileUrl
      }
      return this.url
    }
  },
  methods: {
    handleClick() {
      this.$refs.file.click();
    },
    onChange(event) {
      const maxSize = this.maxSize * 1024 * 1024;
      const file = event.target.files
      if (file[0].size >= maxSize) {
        this.error = true;
        this.errorMessageDocument = `Document tidak boleh melebihi ${this.maxSize}MB`
      } else {

        this.fileName = file[0].name.length > 40 ? file[0].name.substring(0, 37) + '...' : file[0].name;
        this.error = false
        const url = URL.createObjectURL(file[0]);
        this.ImageSrc = url;
        this.$emit("value", [url, file[0]]);
        this.fileUrl = url
      }
    },
    handleClickPreview() {
      window.open(this.previewUrl, '_blank')
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/lbd/_variables.scss";

.file-upload {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  &__component {
    border: 1px solid $dark-gray;
    border-radius: 8px;
    display: flex;
    min-height: 50px;
    flex-direction: column;
    justify-content: center;
    padding-left: 12px;
    font-size: 12px;
    color: $text-base;
    cursor: pointer;
    font-weight: 500; 
  }
  
  &__error {
    margin-top: 8px;
    color: $red-color-bottom;
    flex-basis: 1;
    width: 100%;
  }
}
/deep/.custom-padding {
  padding: 4px 24px ;
  margin-left: 12px;
}
</style>
