import ManageSppPaymentRepository from "../api";

class ManageSppPaymentUseCase {
  constructor() {
    /**
     * @type { ManageSppPaymentRepository }
     */

    this.api = new ManageSppPaymentRepository()
  }

  /**
   * @param { string } page
   * @param { string } perPage
   * @param { string } search
   * @returns { Promise<{data: any | null}, error: string | null }
   */

  async getAll(page, perPage, search, status, orderType) {
    const { data, error } = await this.api.getAll(page, perPage, search, status, orderType)
    return { data, error, pagination: data.pagination }
  }
  
  async getStudenWithParents(search) {
    const { data, error } = await this.api.getStudenWithParents(search)
    return { data, error }
  }
  
  async getProgramByStudentId(id) {
    const { data, error } = await this.api.getProgramByStudentId(id);
    return { data, error };
  }
  
  async getPacketProgram(programId, programType) {
    const { data, error } = await this.api.getPacketProgram(programId, programType);
    return { data, error };
  }

  async create(payload) {
    const { data, error } = await this.api.create(payload)
    return { data, error }
  }
  
  async getSppDueDate() {
    const { data, error } = await this.api.getSppDueDate();
    return { data, error };
  }
  
  async createSppDueDate(payload) {
    const { data, error } = await this.api.createSppDueDate(payload)
    return { data, error }
  }
  
  async updateSppDueDate(id, payload) {
    const { data, error } = await this.api.updateSppDueDate(id, payload)
    return { data, error }
  }
}

export default new ManageSppPaymentUseCase()
