import { MapPagination } from '@/app/Mappers'

export const MapParent = (payload) => {
  return {
    id: payload.id,
    fullName: payload.full_name,
    dob: payload.dob,
    nik: payload.nik,
    phoneNumber: payload.phone_number,
    email: payload.user_email || payload.user.email,
    placeOfBirth: payload.place_of_birth,
    profession: payload.profession,
    educationalBackground: payload.educational_background,
    monthlyIncome: payload.monthly_income,
    type: payload.type,
    phoneNumber: payload.phone_number,
  }
}

export const MapUsers = (payload) => {
  return {
    pagination: MapPagination(payload.meta),
    data: payload.data
  }
}

export const MapPayloadUser = (payload) => {
  return {
    full_name: payload.fullName,
    phone_number: payload.phoneNumber,
    nik: payload.nik,
    place_of_birth: payload.placeOfBirth,
    dob: payload.dob,
    profession: payload.profession,
    educational_background: payload.educationalBackground,
    monthly_income: Number(payload.monthlyIncome),
  }
}
