import CMSInternalAPI from '@/app/BaseAxios'
import { MapProducts, MapProduct } from '../mappers'

export default class ManageProductRepository extends CMSInternalAPI {
  constructor() {
    super()

    /** @type { string } */
    this.endpoint = 'v1/admin/management-product'
  }

  /**
   *
   * @param { string } page
   * @param { string } perPage
   * @param { string } search
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
    async getAll(page = '1', perPage = '10', search = '') {
      try {
        /** @type { import("axios").AxiosResponse<Object> } */
        const response = await this.$axios.get(`${this.endpoint}/list?page=${page}&limit=${perPage}&name=${search}`)
        if(response && response.status === 200) {
          return { data: MapProducts(response.data), error: null }
        }
        return { data: null, error: null }
      } catch (error) {
        return { data: null, error: this.setErrorMessage(error.response?.data) }
      }
    }

  /**
   *
   * @param { number } id
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
    async get(id) {
      try {
        /** @type { import("axios").AxiosResponse<Object> } */
        const response = await this.$axios.get(`${this.endpoint}/detail/${id}`)
        if(response && response.status === 200) {
          return { data: MapProduct(response.data.data), error: null }
        }
        return { data: null, error: null }
      } catch (error) {
        return { data: null, error: this.setErrorMessage(error.response?.data) }
      }
    }

  /**
   *
   * @param { Object } payload
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async create(payload) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.post(`${this.endpoint}/create`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if(response && response.status === 200) {
        return { data: response.data.data, error: null }
      }
      return { data: null, error: response?.response?.data?.error }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }

  /**
   *
   * @param { number } id
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async delete(id) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.delete(`${this.endpoint}/delete/${id}`)
      if(response && response.status === 200) {
        return { data: response.data, error: null }
      }
      return { data: null, error: response?.response?.data?.error }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }


  /**
   *
   * @param { Object } payload
   * @param { id } number
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async edit(payload, id) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.put(`${this.endpoint}/update/${id}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if(response && response.status === 200) {
        return { data: response.data.data, error: null }
      }
      return { data: null, error: response?.response?.data?.error }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
}
