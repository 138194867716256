<template>
  <div class="pagination" :style="`justify-content: ${position}`">
    <div class="pagination__chevron-left" @click="onClick('left')">
      <i class="nc-icon nc-stre-left"></i>
    </div>
    <div class="pagination__page">
      <span>{{ page }}</span>
    </div>
    <div class="pagination__chevron-right" @click="onClick('right')">
      <i class="nc-icon nc-stre-right"></i>
    </div>
    <div class="pagination__total-page"> dari {{ totalPage }}</div>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    props: {
      page: {
        type: Number,
        default: 1
      },
      totalPage: {
        type: Number,
        default: 1
      },
      position: {
        type: String,
        default: 'center'
      }
    },

    methods: {
      onClick(type) {
        if(type === 'left') {
          this.$emit('prev')
        }

        if(type === 'right') {
          this.$emit('next')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '../../assets/sass/lbd/variables';

%button-chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  background-color: $default-color-top;
  cursor: pointer;
}

.pagination {
  display: flex;

  &__page {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $text-base;
    font-weight: 700;
    font-size: 18px;
  }

  &__chevron-left {
    margin-right: 12px;
    @extend %button-chevron;
  }

  &__chevron-right {
    margin-left: 12px;
    @extend %button-chevron;
  }

  &__total-page {
    margin-left: 12px;
    color: #444;
    font-weight: 500;
    font-size: 14px;
    margin-top: 12px;
  }
}
</style>
