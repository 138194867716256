import { MapPagination } from '@/app/Mappers'

export const MapAdmin = (payload) => {
  return {
    id: payload.id,
    fullName: payload.full_name,
    email: payload.email,
    phoneNumber: payload.phone_number,
    position: payload.position,
    role: payload.entity,
    createdAt: payload.created_at,
    updatedAt: payload.updated_at
  }
}

export const MapAdmins = (payload) => {
  return {
    pagination: MapPagination(payload.meta),
    data: payload.data
  }
}

export const MapPayloadAdmin = (payload) => {
  return {
    full_name: payload.name || payload.fullName,
    email: payload.email,
    phone_number: payload.phone || payload.phoneNumber,
    position: payload.position,
    password: payload.password,
    confirm_password: payload.password
  }
}
