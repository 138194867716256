import ManageProductRepository from "../api";

class ManageProductUseCase {
  constructor() {
    /**
     *  @type { ManageProductRepository }
     */

    this.api = new ManageProductRepository()
  }

  /**
   *  @param { string } page
   *  @param { string } perPage
   *  @returns { Promise<{data: any | null, error: string | null} }
   */

  async getAll(page, perPage, search) {
    const { data, error } = await this.api.getAll(page, perPage, search)
    return { data: data, error, pagination: data.pagination }
  }

  /**
   *  @param { number } id
   *  @returns { Promise<{data: any | null, error: string | null} }
   */

  async get(id) {
    const { data, error } = await this.api.get(id)
    return { data, error }
  }

  /**
   *  @param { object } payload
   *  @returns { Promise<{data: any | null, error: string | null} }
   */
  async create(payload) {
    const { data, error } = await this.api.create(payload)
    return { data, error }
  }

  /**
   *  @param { number } id
   *  @returns { Promise<{data: any | null, error: string | null} }
   */

  async delete(id) {
    const { data, error } = await this.api.delete(id)
    return { data, error }
  }

  /**
   *  @param { object } payload
   *  @param { number } id
   *  @returns { Promise<{data: any | null, error: string | null} }
   */
  async edit(payload, id) {
    const { data, error } = await this.api.edit(payload, id)
    return { data, error }
  }
}

export default new ManageProductUseCase()
