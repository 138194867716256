import CMSInternalAPI from "@/app/BaseAxios"
import { getToken } from "@/app/misc/Cookies"
/**
 *  @borrows CMSInternalAPI
 */

export default class DashboardRepository extends CMSInternalAPI {
    constructor() {
        super()

        /** @type { string } */
        this.endpoint = 'v1/admin'
    }

    /**
     *
     * @returns { Promise<{data: any | null, error: string | null}> }
     */
    async get() {
        try {
            /** @type { import("axios").AxiosResponse<Object> } */
            const response = await this.$axios.get(`${this.endpoint}/dashboard`)
            if(response && response.status === 200) {
              return { data: response.data.data, error: null }
            }
            return { data: null, error: null }
        } catch (error) {
            return { data: null, error: this.setErrorMessage(error.message) }
        }
    }
}
