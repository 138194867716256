import CMSInternalAPI from "@/app/BaseAxios";

/**
 * @borrows CMSInternalAPI
 */

export default class LoginRepository extends CMSInternalAPI {
    constructor() {
        super()

        /** @type { string } */
        this.endpoint = 'v1/admin/login'
    }

    /**
     * @returns { Promise<{data: any | null, error: string | null } }
     */

    async login(payload) {
        try {
            /** @type { import("axios").AxiosResponse<Object> } */
            const response = await this.$axios.post(this.endpoint, payload)
            if(response && response.status === 201) {
                return { data: response.data, error: null}
            }
            return { data: null, error: response }
        } catch (error) {
            return { data: null, error: this.setErrorMessage(error.response.data) }
        }
    }
}
