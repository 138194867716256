import ManageTransactionRepository from "../api";

class ManageTransactionUseCase {
  constructor() {
    /**
     * @type { ManageTransactionRepository }
     */

    this.api = new ManageTransactionRepository()
  }

  /**
   *  @param { string } page
   *  @param { string } perPage
   *  @param { string } search
   *  @returns { Promise<{data: any | null, error: string | null} }
   */

  async getAll(page, perPage, search) {
    const { data, error } = await this.api.getAll(page, perPage, search)
    return { data: data.data, error, pagination: data.pagination }
  }

  async get(id) {
    const { data, error } = await this.api.get(id)
    return { data, error }
  }

  async update(id, payload) {
    const { data, error } = await this.api.update(id, payload)
    return { data, error }
  }
}

export default new ManageTransactionUseCase()
