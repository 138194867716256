import LoginRepository from "../api";
import { MapAdminLogin } from "../mappers";

class Authentication {
    constructor() {
        /**
         * @type { LoginRepository }
         */

        this.api = new LoginRepository()
    }

    /**
     * @returns { Promise<{data: any, error: string | null}> }
     */

    async login(payload) {
        const { data, error } = await this.api.login(payload)
        if(data) {
          return { data: MapAdminLogin(data.data), error: error}
        }
        return { data, error }
    }
}

export default new Authentication()
