<template>
  <div class="create-spp-pembayaran">
    <Modal
      :showModal="showModal"
      :isConfirmation="true"
      :useHeader="false"
      width="28"
      @cancel="handleModalConfirmation(ENUM_ACTION.CANCEL)"
      @oke="handleModalConfirmation(ENUM_ACTION.OKE)"
    >
    <div class="text-confirmation">
      <h4>{{ textConfirmation }}</h4>
    </div>
    </Modal>
    <Loader colorClass="l-gray" v-if="isLoading" />
    <div class="create-spp-pembayaran__form">
      <div class="create-spp-pembayaran__form__title">
        
        <!-- MARK: Select Student and Parent / Trustee -->
        <label>Pilih Nama Anak - Orang Tua (Ibu) / Wali <span>*</span></label>
        <div style="width: 50%;">
          <v-select 
            :options="studentWithParentOptions"
            :placeholder="'Select Nama Anak - Orang Tua (Ibu) / Wali'"
            v-model="form.selectedStudenWithParent"
            @input="handleSelectStudentWithParentChange"
          />
        </div>
        
        <hr class="separator">
        
        <!-- MARK: Select Program --> 
        <h3 class="create-spp-pembayaran__section-title">Pilih Program</h3>
        <div class="create-spp-pembayaran__select-program">
          <div class="create-spp-pembayaran__select-program-field">
            <div 
              class="create-spp-pembayaran__select-program-field-item"
              v-for="packet in form.packets"
              :key="packet.id"
            >
              <div class="create-spp-pembayaran__select-program-action">
                <button @click="handleRemoveFieldSelectProgramById(packet.id)">
                  <i class="fa fa-trash fa-md" style="color: #b70f0a;"></i>
              </button>
              </div>
              <div class="create-spp-pembayaran__select-program-field-dropdown">
                <div class="create-spp-pembayaran__select-program-field-dropdown-item">
                  <label>Pilih Program</label>
                  <v-select 
                    :disabled="(!form.selectedStudenWithParent)"
                    :options="programByStudentOptions"
                    :placeholder="'Select program'"
                    v-model="packet.selectedOptionProgram"
                    @input="(selectedValue) => handleSelectProgramByStudentChange(packet.id, selectedValue)"
                  />
                </div>
                <div class="create-spp-pembayaran__select-program-field-dropdown-item">
                  <label>Pilih Sub Program</label>
                  <v-select 
                    :disabled="subProgramByStudentOptions.length > 0 ? (!packet.selectedOptionProgram) : true"
                    :options="subProgramByStudentOptions"
                    :placeholder="'Select sub program'"
                    v-model="packet.selectedOptionsSubProgram"
                    @input="(selectedValue) => handleSelectSubProgramByStudentChange(packet.id, selectedValue)"
                  />
                </div>
                <div class="create-spp-pembayaran__select-program-field-dropdown-item">
                  <label>Pilih Paket Program</label>
                  <v-select 
                    :disabled="subProgramByStudentOptions.length > 0 ? (!packet.selectedOptionsSubProgram) : (!packet.selectedOptionProgram)"
                    :options="packetProgramOptions"
                    :placeholder="'Select paket program'"
                    v-model="packet.selectedOptionPacket"
                    @input="(selectedValue) => handleSelectPacketProgramChange(packet.id, selectedValue)"
                  />
                </div>
                <div>
                  <label>Harga</label>
                  <BaseInput p
                    :disabled="(!packet.selectedOptionPacket)"
                    laceholder="Rp 0" 
                    v-model="packet.packetPrice" 
                  />
                </div>
              </div>
              <div class="create-spp-pembayaran__select-program-action">
                <button @click="handleResetFieldSelectProgramById(packet.id)"><i class="fa fa-refresh fa-md"></i></button>
              </div>
            </div>
            <div class="create-spp-pembayaran__add-field-item">
              <button @click="handleAddFieldSelectProgram()">
                <i class="fa fa-plus fa-md"></i> 
                <span>Tambah Program</span>
              </button>
            </div>
          </div>
        </div>
        
        <hr class="separator">
        
        <!-- MARK: Additional Paid -->
        <h3 class="create-spp-pembayaran__section-title">Biaya Tambahan</h3>
        <div class="create-spp-pembayaran__select-program">
          <div class="create-spp-pembayaran__select-program-field">
            <div 
              class="create-spp-pembayaran__select-program-field-item"
              v-for="additionalPaid in form.additionalPaids"
              :key="additionalPaid.id"
            >
              <div class="create-spp-pembayaran__select-program-action">
                <button @click="handleRemoveAdditionalPaid(additionalPaid.id)">
                  <i class="fa fa-trash fa-md" style="color: #b70f0a;"></i>
                </button>
              </div>
              <div class="create-spp-pembayaran__select-program-field-dropdown">
                <div class="create-spp-pembayaran__select-program-field-dropdown-item">
                  <label>Item</label>
                  <BaseInput 
                    placeholder="Cth. Baju seragam"
                    v-model="additionalPaid.itemName"
                  />
                </div>
                <div class="create-spp-pembayaran__select-program-field-dropdown-item">
                  <label>Qty</label>
                  <BaseInput 
                    placeholder="0" 
                    v-model="additionalPaid.qty"
                  />
                </div>
                <div class="create-spp-pembayaran__select-program-field-dropdown-item">
                  <label>Harga Satuan</label>
                  <BaseInput 
                    placeholder="0" 
                    v-model="additionalPaid.price"
                  />
                </div>
                <div>
                  <label>Jumlah</label>
                  <BaseInput disabled :value="additionalPaid.qty * additionalPaid.price" placeholder="0" />
                </div>
              </div>
              <div class="create-spp-pembayaran__select-program-action">
                <button @click="handleResetAdditionalPaid(additionalPaid.id)"><i class="fa fa-refresh fa-md"></i></button>
              </div>
            </div>
            <div class="create-spp-pembayaran__add-field-item">
              <button @click="handleAddFieldAdditionalPaid()">
                <i class="fa fa-plus fa-md"></i> 
                <span>Tambah Biaya Lainnya</span>
              </button>
            </div>
          </div>
        </div>
        
        <hr class="separator">
        
        <!-- MARK: Cost Details -->
        <h3 class="create-spp-pembayaran__section-title">Rincian Biaya</h3>
        <div v-if="this.tableData.length > 0">
          <div class="table-responsive">
            <CustomTable
              class="table-hover"
              :columns="table.columns"
              :data="tableData"
              @click="{}"
            >
              <template v-slot:tfoot="">
                <tfoot>
                  <tr>
                    <td colspan="3">Total</td>
                    <td>{{ totalPriceTableData }}</td>
                  </tr>
                </tfoot>
              </template>
            </CustomTable>
          </div>
        </div>
        
        <div v-else>
          <p class="table-empty-message">Data tidak ada</p>
        </div>
        
        <hr class="separator">
        
        <!-- MARK: Bank Account -->
        <h3 class="create-spp-pembayaran__section-title">Akun Bank</h3>
        <div class="create-spp-pembayaran__account-bank">
          <div>
            <label>VA Number</label>
            <BaseInput 
              type="number"
              v-model="form.bankAccountNumber" 
              placeholder="Nomor virtual account" 
            />
          </div>
          <div>
            <label>Bank Tujuan</label>
            <BaseInput v-model="form.bankAccountName" placeholder="Masukkan bank tujuan" />
          </div>
        </div>
        
        <hr class="separator">
          
      </div>
      <div class="create-spp-pembayaran__form__actions">
        <Button type="danger" text="Kembali" @click="handleFormSubmit(ENUM_ACTION.BACK)" />
        <Button :isDisabled="$v.form.$invalid" type="info" text="Submit" @click="handleFormSubmit(ENUM_ACTION.SUBMIT)" />
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import { formatCurrancy } from '@/app/utils';
import { ENUM_ACTION } from '@/app/Constants';
import CustomTable from "src/components/Table.vue";
import ManageSppPaymentUseCase from '../app/usecase';

export default {
  components: {
    CustomTable,
  },
  mixins: [
    validationMixin,
  ],
  data() {
    return {
      form: {
        studentId: null,
        selectedStudenWithParent: null,
        packets: [
          {
            id: 0,
            packetId: null,
            packetName: null,
            packetPrice: null,
            selectedOptionProgram: null,
            selectedOptionsSubProgram: null,
            selectedOptionPacket: null,
          },
        ],
        additionalPaids: [
          {
            id: 0,
            itemName: null,
            qty: null,
            price: null,
          },
        ],
        bankAccountName: null,
        bankAccountNumber: null,
      },
      table: {
        columns: [
          { label: 'Item', value: 'item' },
          { label: 'Qty', value: 'qty' },
          { label: 'Harga Satuan', value: 'price' },
          { label: 'Jumlah', value: 'total' },
        ],
        data: [],
      },
      selectedOption: null,
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' },
      ],
      studentWithParentOptions: [],
      programByStudentOptions: [],
      subProgramByStudentOptions: [],
      packetProgramOptions: [],
      ENUM_ACTION,
      showModal: false,
      isChange: false,
      isSubmit: false,
      textConfirmation: '',
      isLoading: false,
      message: {
        success: 'SPP Pembayaran berhasil dibuat',
        failed: 'SPP Pembayaran gagal dibuat'
      },
    }
  },
  computed: {
    tableData() {
      const data = [];
      const dataPackets = this.form.packets.filter((item) => item.selectedOptionPacket !== null);
      const dataAdditionalPaids = this.form.additionalPaids.filter((item) => item.price !== null);
      
      dataPackets.forEach((item) => {
        data.push({
          item: `${item.selectedOptionProgram.label} - ${item.selectedOptionPacket.label}`,
          qty: 1,
          price: formatCurrancy(Number(item.packetPrice)),
          total: formatCurrancy(Number(item.packetPrice)),
          totalRaw: Number(item.packetPrice),
        });
      });
      
      dataAdditionalPaids.forEach((item) => {
        data.push({
          item: item.itemName,
          qty: Number(item.qty),
          price: formatCurrancy(Number(item.price)),
          total: formatCurrancy(Number(item.qty) * Number(item.price)),
          totalRaw: Number(item.qty) * Number(item.price),
        });
      });
      
      return data;
    },
    totalPriceTableData() {
      const total = this.tableData.reduce((acc, curr) => acc + Number(curr.totalRaw), 0);
      return formatCurrancy(total);
    },
  },
  methods: {
    async handleGetStudentWithParents() {
      const response = await ManageSppPaymentUseCase.getStudenWithParents('');
      
      if (response && !response.error) {
        const responseData = response.data?.data || []
        
        this.studentWithParentOptions = responseData.map((item) => ({
          label: item.studentWithParent,
          value: item.studentId,
        }));
      }
    },
    async handleGetProgramByStudentId(id) {
      const response = await ManageSppPaymentUseCase.getProgramByStudentId(id);
      if (response && !response.error) {
        const responseData = response.data?.data || []
        
        this.programByStudentOptions = responseData.map((item) => ({
          label: item.programName,
          value: item.programId,
          programType: item.programType,
          subPrograms: item.subPrograms,
        }));
      }
    },
    async handleGetPacketProgram(programId, programType = 'program') {
      const response = await ManageSppPaymentUseCase.getPacketProgram(programId, programType)
      
      if (response && !response.error) {
        const responseData = response.data?.data || []
        
        this.packetProgramOptions = responseData.map((item) => ({
          label: item.packetName,
          value: item.packetId,
          price: item.packetPrice,
        }));
      }
    },
    handleSelectStudentWithParentChange(selectedValue) {
      if (selectedValue) {
        this.form.selectedStudenWithParent = selectedValue;
        this.form.studentId = selectedValue.value;
        this.handleGetProgramByStudentId(selectedValue.value);
        this.form.packets = this.form.packets.map((packet) => ({
          ...packet,
          packetPrice: null,
          selectedOptionProgram: null,
          selectedOptionPacket: null,
        }));
      } else {
        this.form.selectedStudenWithParent = null;
        this.form.studentId = null;
        this.programByStudentOptions = [];
        this.form.packets = this.form.packets.map((packet) => ({
          ...packet,
          packetPrice: null,
          selectedOptionProgram: null,
          selectedOptionPacket: null,
        }));
      }
    },
    handleSelectProgramByStudentChange(inputId, selectedValue) {
      if (selectedValue) {
        this.form.packets = this.form.packets.map((packet) => {
          if (packet.id === inputId) {
            return {
              ...packet,
              selectedOptionProgram: selectedValue,
            };
          }
          
          return packet;
        });
        
        if (selectedValue.subPrograms.length > 0) {
          this.subProgramByStudentOptions = selectedValue.subPrograms.map((item) => ({
            label: item.programName,
            value: item.programId,
            programType: item.programType,
          }));
        } else {
          this.handleGetPacketProgram(selectedValue.value, selectedValue.programType);
        }
      } else {
        this.subProgramByStudentOptions = [];
        this.form.packets = this.form.packets.map((packet) => ({
          ...packet,
          packetPrice: null,
          selectedOptionProgram: null,
          selectedOptionsSubProgram: null,
          selectedOptionPacket: null,
        }));
      }
    },
    handleSelectSubProgramByStudentChange(inputId, selectedValue) {
      if (selectedValue) {
        this.form.packets = this.form.packets.map((packet) => {
          if (packet.id === inputId) {
            return {
              ...packet,
              selectedOptionsSubProgram: selectedValue,
            };
          }
          
          return packet;
        });
        
        this.handleGetPacketProgram(selectedValue.value, selectedValue.programType);
      } else {
        this.packetProgramOptions = [];
        this.form.packets = this.form.packets.map((packet) => ({
          ...packet,
          packetPrice: null,
          selectedOptionsSubProgram: null,
          selectedOptionPacket: null,
        }));
      }
    },
    handleSelectPacketProgramChange(inputId, selectedValue) {
      if (selectedValue) {
        this.form.packets = this.form.packets.map((item) => {
          if (item.id === inputId) {
            return {
              ...item,
              packetPrice: selectedValue.price,
            };
          }
          
          return item;
        });
      } else {
        this.form.packets = this.form.packets.map((packet) => ({
          ...packet,
          packetPrice: null,
          selectedOptionPacket: null,
        }));
      }
    },
    handleAddFieldSelectProgram() {
      const latestId = this.form.packets[this.form.packets.length - 1]?.id || 0;
      this.form.packets.push({
        id: latestId + 1,
        packetId: null,
        packetPrice: null,
        selectedOptionProgram: null,
        selectedOptionPacket: null,
      });
    },
    handleRemoveFieldSelectProgramById(id) {
      this.form.packets = this.form.packets.filter((item) => item.id !== id);
    },
    handleResetFieldSelectProgramById(id) {
      this.form.packets = this.form.packets.map((item) => {
        if (item.id === id) {
          return {
            id: id,
            packetId: null,
            packetName: null,
            packetPrice: null,
            selectedOptionProgram: null,
            selectedOptionPacket: null,
          };
        }
        
        return item;
      });
    },
    handleAddFieldAdditionalPaid() {
      const latestId = this.form.additionalPaids[this.form.additionalPaids.length - 1]?.id || 0;
      this.form.additionalPaids.push({
        id: latestId + 1,
        itemName: null,
        qty: null,
        price: null,
        totalPrice: null,
      });
    },
    handleRemoveAdditionalPaid(id) {
      this.form.additionalPaids = this.form.additionalPaids.filter((item) => item.id !== id);
    },
    handleResetAdditionalPaid(id) {
      this.form.additionalPaids = this.form.additionalPaids.map((item) => {
        if (item.id === id) {
          return {
            id: id,
            itemName: null,
            qty: null,
            price: null,
            totalPrice: null,
          };
        }
        
        return item;
      });
    },
    handleFormSubmit(type) {
      if(type === ENUM_ACTION.BACK) {
        if(!this.isChange) {
          this.$router.go(-1)
        } else {
          this.isSubmit = false
          this.textConfirmation = 'Apakah anda yakin menghapus perubahan ?',
          this.showModal = true
        }
      }

      if(type === ENUM_ACTION.SUBMIT) {
        this.isSubmit = true
        this.textConfirmation = 'Apakah data sudah benar ?'
        this.showModal = true
      }
    },
    handleModalConfirmation(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModal = false
      }

      if(type === ENUM_ACTION.OKE) {
        if(!this.isSubmit) {
          this.$router.push('/spp-pembayaran')
        } else {
          this.handleCreate()
        }
        this.showModal = false
      }
    },
    handleShowToast(verticalAlign, horizontalAlign, color, typeMessage) {
      this.$notifications.notify({
        message: this.message[typeMessage],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    async handleCreate() {
      this.isLoading = true
      
      const formData = this.form;
      
      const payload = {
        student_id: Number(formData.selectedStudenWithParent?.value),
        packets: formData.packets.map((item) => ({
          packet_id: Number(item.selectedOptionPacket?.value),
          packet_price: Number(item.packetPrice)
        })),
        additional_paids: formData.additionalPaids.map((item) => ({
          item_name: item.itemName,
          qty: Number(item.qty),
          price: Number(item.price),
          total_price: Number(item.qty) * Number(item.price),
        })),
        bank_account_name: formData.bankAccountName,
        bank_account_number: formData.bankAccountNumber,
      };

      const response = await ManageSppPaymentUseCase.create(payload)
      if (response && !response.error) {
        this.handleShowToast('top', 'right', 'success', 'success')
        this.$router.push('/spp-pembayaran')
      } else {
        this.handleShowToast('top', 'right', 'danger', 'failed')
      }
      this.isLoading = false

      this.showModal = false
    },
  },
  validations: {
    form: {
      selectedStudenWithParent: {
        required,
      },
      bankAccountName: {
        required,
      },
      bankAccountNumber: {
        required
      },
    },
  },
  created() {
    this.handleGetStudentWithParents();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/sass/lbd/variables';

.create-spp-pembayaran {
  width: 90%;
  background-color: white;
  margin: auto;
  padding: 20px;
  
  &__section-title {
    font-size: 24px;
    font-weight: 500;
    color: #4d4d4d;
    margin-bottom: 24px;
  }
  
  &__select-program-field-item {
    display: flex;
    gap: 24px;;
  }
  
  &__select-program-action {
    display: flex;
    justify-content: center;
    align-items: center;
    
    > button {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #7a7a7a;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  &__select-program-field-dropdown {
    display: flex;
    gap: 24px;
    flex-basis: 100%;
  }
  
  &__select-program-field-dropdown-item {
    flex-grow: 1;
  }
  
  &__add-field-item {
    > button {
      border: none;
      width: 100%;
      height: 32px;
      background: #629c44;
      color: white;
      font-weight: bold;
      font-size: 14px;
      border-radius: 2px;
    }
  }
  
  &__account-bank {
    display: flex;
    gap: 24px;
    
    > div {
      flex-basis: 380px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    > div:first-child {
      margin-bottom: 12px;
    }

    > div {
      margin-bottom: 32px;

      > label {
        color: $text-base;
        font-weight: 600;

        span {
          color: $danger-states-color;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      > div {
        margin-right: 12px;
      }
    }
  }
}

.text-confirmation {
  text-align: center;
  margin-bottom: 20px;
}

.separator {
  margin-top: 40px;
}

.table-empty-message {
  text-align: center;
  font-weight: bold;
}
</style>
