<template>
  <div class="breadcrumb">
    <div class="breadcrumb__item">
      <div class="nc-icon nc-bag"></div>
      <div v-for="(item, index) of listPath" :key="`${item}-${index}`" class="">
        <span>/</span>
        <h4>{{ item }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumb: []
    }
  },
  computed: {
    listPath() {
      let fullpath = []
      this.$route.matched.map((item) => {
        if(Boolean(item.name)) {
          if(item.name.includes(':')) {
            const name = item.name.replace(':', '')
            fullpath.push(
              item.parent.path
              .replace('/', '')
              .replace('-', ' ')
              .split(' ')
              .map(item =>
                item.replace(item[0], item[0].toUpperCase()))
              .join(' '))
            fullpath.push(this.$route.params[name])
          } else {
            const url = this.$route.path
            .split('')
            .map(item => item.replace('/', ' '))
            .join('')
            .replace(' ', '')
            .split(' ')
            .map(item =>
              item.replace('-', ' ')
                  .split(' ')
                  .map(path => path.replace(path[0], path[0].toUpperCase()))
                  .join(' '));
            if(url[0] === this.$route.name) {
              fullpath.push(url[0])
            } else {
              fullpath.push(url[0])
              fullpath.push(this.$route.name)
            }
          }
        }
      })
      return fullpath
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  background-color: white;
  margin: 20px;

  &__item {
    display: flex;

    > div:first-child {
      font-weight: bold;
      font-size: 24px;
      padding-right: 12px;
      margin: 0;
    }

    > div {
      font-size: 24px;
      display: flex;
      align-items: center;

      > span {
        margin: 0 12px;
      }

      > h4 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
        color: #4a4a4a;
      }
    }
  }
}
</style>
