import DashboardRepository from "../api";

class DashboardUseCase {
    constructor() {
        /**
         * @type { DashboardRepository }
         */

        this.api = new DashboardRepository()
    }

    /**
     * @return { Promise<{data: any, error: string | null}> }
     */

    async get() {
        const { data, error } = await this.api.get()
        return { data, error }
    }
}

export default new DashboardUseCase()
