export const MapAdminLogin = (payload) => {
  return {
    accessToken: payload.access_token,
    id: payload.id,
    fullName: payload.full_name,
    email: payload.email,
    phoneNumber: payload.phone_number,
    Role: payload.entity,
    verifiedType: payload.verified_type
  }
}
