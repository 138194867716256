<template>
    <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <Card class="card-plain">
            <template slot="header">
              <div class="header">
                <div class="header__title">
                  <h4 class="card-title">Management Artikel</h4>
                  <p class="card-category">Management content artikel</p>
                </div>
                <Button text="Tambah Artikel" @click="() => this.$router.push('/management-article/create')" />
              </div>
            </template>
            <div class="form-filter">
              <BaseInput width="50" v-model="search" placeholder="Cari Berdasarkan title" />
              <Button type="info" text="Cari" @click="onSearch" />
            </div>
            <div class="table-responsive">
              <l-table
                class="table-hover"
                :columns="table.columns"
                :data="table.data"
                @click="(e) => handleClick(e)"
              >
              </l-table>
            </div>
          </Card>
        </div>
        <Loader v-show="isLoading" colorClass="l-gray" />
      </div>
      <Pagination
        :page="pagination.page"
        @prev="onClick('prev')"
        @next="onClick('next')"
      />
    </div>
  </div>
</template>

<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import Pagination from '@/components/Pagination/Pagination.vue'
import Loader from '@/components/Loader/Loader.vue'
import ManageArticleUseCase from './app/usecase'
import { MapArticle } from './app/mappers'

export default {
  components: {
    LTable,
    Card,
    Pagination,
    BaseInput,
    Loader
  },
  data() {
    return {
      table: {
        columns: [
        { label: 'Title', value: 'title',  style: 'width: 300px;' },
        { label: 'Category', value: 'category',  style: 'width: 300px;' },
        { label: 'Dibuat', value: 'createdAt' },
        { label: 'Diupdate', value: 'updatedAt' }
        ],
        data: []
      },
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1
      },
      search: '',
      isLoading: false
    }
  },
  methods: {
    onSearch() {
      this.getAllArticles()
    },
    async getAllArticles() {
      this.isLoading = true
      const response = await ManageArticleUseCase.getAll(
        this.pagination.page,
        this.pagination.perPage,
        this.search
      )

      if(response && !response.error) {
        this.table.data = response.data.map(item => {
          const article = MapArticle(item)
          return article
        })
        this.pagination = response.pagination
      } else {
        this.$notifications.notify({
          message: 'Gagal memuat data',
          icon: "nc-icon nc-app",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger',
        })
      }

      this.isLoading = false
    },
    onClick(type) {
      if (type === "prev" && this.pagination.page !== 1) {
        this.pagination.page--;
      }

      if (type === "next" && this.pagination.page !== this.pagination.totalPage) {
        this.pagination.page++;
      }

      this.getAllArticles()
    },
    handleClick(value) {
      this.$router.push(`${this.$route.path}/detail/${value.title}?id=${value.id}`);
    },
  },
  created() {
    this.getAllArticles()
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.form-filter {
  display: flex;

  > div:first-child {
    margin-right: 20px;
  }
}
</style>
