import { TimeMapper } from '@/app/utils'
import { formatCurrancy } from '../../../../app/utils'

export const MapPrograms = (response) => {
  return {
    data: response.data
  }
}

export const MapSubPrograms = (response) => {
  if (response?.length < 1) return []
  return response?.map((resp) => {
    return {
      id: resp?.id,
      name: resp?.name,
      description: resp?.description,
      monthlyPrice: resp?.monthly_price ? formatCurrancy(resp?.monthly_price) : 'Rp. 0',
      programId: resp?.program_id,
      programType: resp?.program_type,
      createdAt: TimeMapper(resp?.created_at),
      updatedAt: TimeMapper(resp?.updated_at),
      deletedAt: TimeMapper(resp?.deleted_at),
    }
  })
}

export const MapProgram = (response) => {
  return {
    id: response?.id,
    name: response?.name,
    description: response?.description,
    monthlyPrice: formatCurrancy(response?.monthly_price),
    programId: response?.program_id,
    programType: response?.program_type,
    createdAt: TimeMapper(response?.created_at),
    updatedAt: TimeMapper(response?.updated_at),
    deletedAt: TimeMapper(response?.deleted_at),
  }
}
