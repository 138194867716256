import ManageUserRepository from "../api";

class ManageUserUseCase {
  constructor() {
    /**
     * @type { ManageAdminRepository }
     */

    this.api = new ManageUserRepository()
  }

  /**
   * @param { string } search
   * @returns { Promise<{data: any | null}, error: string | null }
   */

  async getAll(search) {
    const { data, error } = await this.api.getAll(search)
    return { data, error }
  }

  async get(id) {
    const { data, error } = await this.api.get(id)
    return { data, error }
  }

  async create(payload) {
    const { data, error } = await this.api.create(payload)
    return { data, error }
  }

  async delete(id) {
    const { data, error } = await this.api.delete(id)
    return { data, error }
  }

  async update(id, payload) {
    const { data, error } = await this.api.update(id, payload)
    return { data, error }
  }
}

export default new ManageUserUseCase()
