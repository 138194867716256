import CMSInternalAPI from "@/app/BaseAxios";
import { MapAdmins, MapAdmin } from "../mappers";

export default class ManageAdminRepository extends CMSInternalAPI {
  constructor() {
    super()

    /** @type { string } */
    this.endpoint = 'v1/admin/management-admin'
  }

  /**
   *
   * @param { string } page
   * @param { string } perPage
   * @param { string } search
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async getAll(page = '1', perPage = '10', search = '') {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.get(`${this.endpoint}/list?page=${page}&limit=${perPage}&full_name=${search}`)
      if(response && response.status === 200) {
        return { data: MapAdmins(response.data), error: null }
      }
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }

  /**
   *
   * @param { number } id
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async get(id) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.get(`${this.endpoint}/detail/${id}`)
      if(response && response.status === 200) {
        return { data: MapAdmin(response.data.data), error: null }
      }
      return { data: null, error: null }
    } catch(error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }

  /**
   *
   * @param { object } payload
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async create(payload) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.post(`${this.endpoint}/create`, payload)
      if(response && response.status === 201) {
        return { data: response.data, error: null }
      }
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }

    /**
   *
   * @param { number } id
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async delete(id) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.delete(`${this.endpoint}/delete/${id}`)
      if(response && response.status === 200) {
        return { data: response.data, error: null }
      }
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }

    /**
   *
   * @param { number } id
   * @param { object } payload
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async update(id, payload) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.put(`${this.endpoint}/update/${id}`, payload)
      if(response && response.status === 200) {
        return { data: response.data, error: null }
      }
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
}
