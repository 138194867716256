import CMSInternalAPI from '@/app/BaseAxios'
import { MapTransaction, MapTransactions } from '../mappers'

export default class ManageTransactionRepository extends CMSInternalAPI {
  constructor() {
    super()

    /** @type { string } */
    this.endpoint = 'v1/admin/management-transaction'
  }

    /**
   *
   * @param { string } page
   * @param { string } perPage
   * @param { string } search
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
    async getAll(page = '1', perPage = '10', search = '') {
      try {
        /** @type { import("axios").AxiosResponse<Object> } */
        const response = await this.$axios.get(`${this.endpoint}/list?page=${page}&limit=${perPage}&invoice_number=${search}`)
        if(response && response.status === 200) {
          return { data: MapTransactions(response.data), error: null }
        }
        return { data: null, error: null }
      } catch (error) {
        return { data: null, error: this.setErrorMessage(error.response?.data) }
      }
    }

  /**
   *
   * @param { number } id
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async get(id) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.get(`${this.endpoint}/detail/${id}`)
      if(response && response.status === 200) {
        return { data: MapTransaction(response.data.data), error: null }
      }
      return { data: null, error: null }
    } catch(error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }

  /**
   *
   * @param { number } id
   * @param { object } payload
   * @returns { Promise<{data: any | null, error: string | null }> }
   */
  async update(id, payload) {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.put(`${this.endpoint}/update-status/${id}`, payload)
      if(response && response.status === 200) {
        return { data: response.data, error: null }
      }
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
}
