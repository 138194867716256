import { MapPagination } from '@/app/Mappers'
import { TimeMapper } from '@/app/utils'

export const MapBroadcasts = (res) => {
  return {
    data: res.data.map(item => {
      return MapBroadcast(item)
    }),
    pagination: MapPagination(res.meta),
    status: res.status_code
  }
}

export const MapBroadcast = (res) => {
  return {
    id: res.id,
    createdAt: TimeMapper(res.created_at, false),
    subject: res.subject,
    totalNumberOfEmail: res.total_number_of_email,
    updatedAt: TimeMapper(res.updated_at, false)
  }
}
