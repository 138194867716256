<template>
    <div class="management-user">
    <BackComponent />
    <Loader v-show="isLoading" colorClass="l-gray" />
    <div class="management-user__title">
      <h2>Detail</h2>
    </div>
    <div class="management-user__profile">
      <div class="management-user__profile__foto">
        <img :src="ImageUser" alt="" />
      </div>
      <div class="management-user__profile__detail">
        <label>Nama: </label>
        <p v-if="state === ENUM_ACTION.DETAIL">{{ data.fullName }}</p>
        <BaseInput
          v-else
          type="text"
          :value="data.fullName"
          @input="(e) => handleInput(e, 'fullName')"
        />

        <label>Email: </label>
        <p v-if="state === ENUM_ACTION.DETAIL">{{ data.email }}</p>
        <BaseInput
          v-else
          type="text"
          :value="data.email"
          @input="(e) => handleInput(e, 'email')"
        />

        <label>Nomor handphone: </label>
        <p v-if="state === ENUM_ACTION.DETAIL">{{ data.phoneNumber }}</p>
        <BaseInput
          v-else
          type="number"
          addonLeftIcon="+62"
          :value="formatPhone(data.phoneNumber)"
          @input="(value) => onInputPhone(value)"
        />

        <label v-if="state === ENUM_ACTION.EDIT">Password: </label>
        <BaseInput
          v-if="state === ENUM_ACTION.EDIT"
          @input="(e) => handleInput(e, 'password')"
          placeholder="Masukan password"
          addonLeftIcon="nc-icon nc-key-25"
          addonRightIcon="nc-icon nc-tap-01"
          v-model="form.password"
          @showHide="togglePassword"
          @focus="onFocus('focus')"
          @blur="onFocus('blur')"
          :type="typePassword"
        />

        <label>Alamat: </label>
        <p v-if="state === ENUM_ACTION.DETAIL">{{ data.position }}</p>
        <textarea
          v-else
          type="text"
          :value="data.position"
          @input="(e) => handleInput(e.target.value, 'position')"
        />

      </div>
    </div>

    <!-- open Modal remove -->
    <Modal
      :showModal="isShowModalRemove"
      :useHeader="false"
      :isConfirmation="true"
      width="32"
      @cancel="onActionModal(ENUM_ACTION.CANCEL)"
      @oke="onActionModal(ENUM_ACTION.OKE)"
    >
      <template>
        <div class="modal-user">
          <h4>Apakah anda yakin ingin menghapus user tersebut ?</h4>
        </div>
      </template>
    </Modal>

    <!-- open Modal update -->
    <Modal
      :showModal="isShowModalUpdate"
      :useHeader="false"
      :isConfirmation="true"
      width="32"
      @cancel="onActionModalUpdate(ENUM_ACTION.CANCEL)"
      @oke="onActionModalUpdate(ENUM_ACTION.OKE)"
    >
      <template>
        <div class="modal-user">
          <h4>{{ textConfirmation }}</h4>
        </div>
      </template>
    </Modal>

    <div class="management-user__actions">
      <Button
        v-if="state === ENUM_ACTION.DETAIL"
        type="info"
        text="Edit"
        @click="onActions(ENUM_ACTION.EDIT)"
      />
      <Button
        v-if="state === ENUM_ACTION.DETAIL"
        type="danger"
        text="Hapus"
        @click="onActions(ENUM_ACTION.DELETE)"
      />
      <div v-else style="display: flex">
        <Button
          style="margin-right: 10px"
          type="info"
          text="Kembali"
          @click="onActions(ENUM_ACTION.BACK)"
        />
        <Button
          type="success"
          text="Simpan"
          @click="onActions(ENUM_ACTION.SUBMIT)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader/Loader.vue';
import BaseInput from '@/components/Inputs/BaseInput.vue';
import ImageUser from '@/assets/img/manageUser/find_user.svg'
import ManageUserUseCase from '../app/usecase'
import Button from '@/components/Button/Button.vue';
import Modal from '@/components/Modal/Modal.vue'
import { MapPayloadUser } from '../app/mappers'
import { validationWithRegex } from '@/app/utils';
import { ERROR_MESSAGE, ENUM_ACTION } from '@/app/Constants'

export default {
  components: {
    Loader,
    BaseInput,
    Button,
    Modal
  },
  data() {
    return {
      ENUM_ACTION,
      ImageUser,
      state: 'DETAIL',
      isLoading: false,
      type: 'text',
      isShowModalRemove: false,
      isShowModalUpdate: false,
      isChange: false,
      textConfirmation: '',
      payload: null,
      data: {
        fullName: '',
        position: '',
        phoneNumber: ''
      },
      form: {
        fullName: "",
        email: "",
        phoneNumber: "",
        position: "",
        password: ""
      },
      formUpdate: {
        fullName: false,
        email: false,
        phoneNumber: false,
        position: false,
        password: false
      },
      message: {
        success: "Berhasil menghapus data akun user",
        failed: "Gagal Menghapus data",
        updated: "Berhasil mengubah data akun user",
        failedUpdate: "Gagal mengubah data akun user",
        empty: 'Data anda tidak boleh kosong',
        email: 'Email anda tidak valid',
        name: 'nama tidak boleh angka',
        password: 'Password harus lebih dari 8 huruf',
        phone: 'Nomor handphone setidak nya 9 angka sampai 15 angka',
        position: 'Pastikan alamat diisi dengan benar'
      },
    }
  },
  computed: {
    typePassword() {
      return this.type;
    },
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, typeMessage) {
      this.$notifications.notify({
        message: this.message[typeMessage],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    async getUser() {
      this.isLoading = true
      const response = await ManageUserUseCase.get(this.$route.query.id)
      if(response && !response.error) {
        this.data = response.data
      }
      this.isLoading = false
    },
    onActions(type) {
      if(type === ENUM_ACTION.EDIT) {
        this.state = ENUM_ACTION.EDIT
      }

      if(type === ENUM_ACTION.DELETE) {
        this.isShowModalRemove = true;
      }

      if(type === ENUM_ACTION.SUBMIT) {
        for (let key in this.formUpdate) {
          if (this.formUpdate[key]) {
            this.isShowModalUpdate = true;
            this.textConfirmation =
              "Apakah anda yakin ingin mengubah data tersebut ?";
            this.payload = {
              ...this.payload,
            };
            this.payload[key] = this.form[key];
          }
        }
      }

      if(type === ENUM_ACTION.BACK) {
        if (this.isChange) {
          this.isShowModalUpdate = true;
          this.textConfirmation =
            "Apakah anda yakin ingin menghapus perubahan data tersebut ?";
        } else {
          this.state = ENUM_ACTION.DETAIL;
        }
      }
    },
    handleInput(value, type) {
      this.isChange = true;
      this.form[type] = value;
      this.data[type] = value;
      this.formUpdate[type] = true;
    },
    formatPhone(value) {
      if (!this.formUpdate.phoneNumber) {
        return value.split("").slice(2).join("");
      } else {
        return value;
      }
    },
    resetState() {
      this.form = {
        fullName: "",
        email: "",
        phoneNumber: "",
        position: "",
        password: ""
      };
      this.formUpdate = {
        fullName: false,
        email: false,
        phoneNumber: false,
        position: false,
        password: ""
      };
    },
    onFocus(type) {
      if (type === "blur" && !this.form.password) {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    togglePassword() {
      if (this.type === "password") this.type = "text";
      else this.type = "password";
    },
    async onActionModal(type) {
      if (type === ENUM_ACTION.CANCEL) {
        this.isShowModalRemove = false;
      }
      if (type === ENUM_ACTION.OKE) {
        const response = await ManageUserUseCase.delete(this.$route.query.id);
        if (response.data) {
          this.notifyVue("top", "right", "success", "success");
          this.$router.push("/management-user");
        } else {
          this.notifyVue("top", "right", "danger", "failed");
        }
        this.isShowModalRemove = false;
      }
    },
    async onActionModalUpdate(type) {
      if (type === ENUM_ACTION.CANCEL) {
        this.isShowModalUpdate = false;
        this.payload = null;
      }

      if (type === ENUM_ACTION.OKE) {
        this.isChange = false;
        if (this.payload) {
          this.isLoading = true
          const resp = await this.updateDataUser(
            this.$route.query.id,
            MapPayloadUser(this.payload)
          );
          if (resp && !resp.error) {
            this.state = ENUM_ACTION.DETAIL;
            this.getUser();
          }
          this.isShowModalUpdate = false;
        } else {
          this.state = ENUM_ACTION.DETAIL;
          this.isShowModalUpdate = false;
        }
      }
    },
    async updateDataUser(id, payload) {
      this.isLoading = true;
      if(this.validation()) {
        const response = await ManageUserUseCase.update(id, payload);
        if (response && !response.error) {
          this.notifyVue("top", "right", "success", "updated");
          return true
        } else if (response.error) {
          this.error.push('response')
          this.message.response = ERROR_MESSAGE[response.error.error_code]
          this.notifyVue('top', 'right', 'danger', 'response')
        } else {
          this.error.map(item => {
            this.notifyVue('top', 'right', 'danger', item)
          })
        }
      } else {
        this.error.map(item => {
          this.notifyVue('top', 'right', 'danger', item)
        })
      }

      this.isLoading = false
    },
    onInputPhone(value) {
      const el = document.querySelector('input[type="number"]');
      if (this.form.phoneNumber.length < 1 && value[0] !== "8") {
        el.value = "";
        return;
      } else {
        if (value.length > 15) {
          el.value = this.data.phoneNumber;
        } else {
          this.form.phoneNumber = value;
          this.data.phoneNumber = value;
        }
      }

      this.formUpdate.phoneNumber = true;
      this.isChange = true;
    },
    validation() {
      this.error = []
      let result = false;
        if (this.formUpdate.email && validationWithRegex(this.form.email, 'EMAIL')) {
          if(!Boolean(this.error.indexOf('email') + 1)) {
            this.error.push('email')
          }
        }

        if(this.formUpdate.fullName && validationWithRegex(this.form.fullName, 'NAME')) {
          if(!Boolean(this.error.indexOf('name') + 1)) {
            this.error.push('name')
          }
        }

        if (this.formUpdate.password && this.form.password.length < 7) {
          if(!Boolean(this.error.indexOf('password') + 1)) {
            this.error.push('password')
          }
        }

        if (this.formUpdate.phoneNumber && this.form.phoneNumber.length < 9 || this.form.phoneNumber.length > 15) {
          if(!Boolean(this.error.indexOf('phone') + 1)) {
            this.error.push('phone')
          }
        }

        if (this.formUpdate.position && validationWithRegex(this.form.position, 'ADDRESS')) {
          if(!Boolean(this.error.indexOf('position') + 1)) {
            this.error.push('position')
          }
        }

        if(this.error.length === 0) {
          result = true
        }

      return result;
    },
  },
  created() {
    this.getUser()
  },
  watch: {
    state() {
      this.getUser();
      this.resetState();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/lbd/variables";

.management-user {
  width: 80%;
  background-color: white;
  border-radius: 8px;
  margin: auto;
  box-shadow: $box-shadow;
  padding: 0px 20px;
  padding-bottom: 32px;
  color: $dark-gray;
  font-weight: 500;

  &__title {
    margin: 0;
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid $light-gray;
    margin-bottom: 32px;

    /deep/ h2 {
      color: $black-hr;
      font-weight: 600;
    }
  }

  &__profile {
    display: flex;

    &__foto {
      width: 40%;

      > img {
        width: 380px;
        object-fit: cover;
      }
    }

    &__detail {
      display: grid;
      grid-template-columns: auto auto;
      max-width: 600px;
      margin-bottom: 18px;

      label {
        color: $text-base;
        font-weight: 600;
        margin-right: 20px;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    > div {
      margin-right: 16px;
    }
  }
}

.modal-user {
  margin-bottom: 32px;

  /deep/ h4 {
    text-align: center;
    color: $text-base;
  }
}

/deep/.custom-padding {
  padding: 8px 60px;
}

textarea {
  color: $text-base;
  border: 1px solid $light-gray;
  border-radius: 4px;
}

textarea:focus {
  outline: none;
  border: 1px solid $dark-gray;
}
</style>
