import { render, staticRenderFns } from "./ManageProgramEdit.vue?vue&type=template&id=580c93a4&scoped=true"
import script from "./ManageProgramEdit.vue?vue&type=script&lang=js"
export * from "./ManageProgramEdit.vue?vue&type=script&lang=js"
import style0 from "./ManageProgramEdit.vue?vue&type=style&index=0&id=580c93a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580c93a4",
  null
  
)

export default component.exports