<template>
  <div class="edit-program">
    <Modal
      :showModal="showModal"
      :isConfirmation="true"
      :useHeader="false"
      width="28"
      @cancel="onModalActions(ENUM_ACTION.CANCEL)"
      @oke="onModalActions(ENUM_ACTION.OKE)"
    >
    <div class="text-confirmation">
      <h4>{{ textConfirmation }}</h4>
    </div>
    </Modal>
    <Loader colorClass="l-gray" v-if="isLoading" />
    <div class="edit-program__form">
      <div class="edit-program__form__title">
        <label>Nama Paket Program<span>*</span></label>
        <BaseInput v-model="form.name" placeholder="Silahkan masukan nama"/>
      </div>
      <div class="edit-program__form__title">
        <label>Harga Per Bulan<span>*</span></label>
        <BaseInput type="currency" v-model="form.monthlyPrice" placeholder="Silahkan masukan nama"/>
      </div>
      <div class="edit-program__form__description">
        <label>Deskripsi <span>*</span></label>
        <VueEditor
          v-model="form.description"
          placeholder="Silahkan masukan deskripsi paket program"
          @input="{}"
        />
      </div>
      <div class="edit-program__form__actions">
        <Button type="danger" text="Kembali" @click="onActions(ENUM_ACTION.BACK)" />
        <Button :isDisabled="$v.form.$invalid" type="info" text="Simpan" @click="onActions(ENUM_ACTION.SUBMIT)" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { ENUM_ACTION } from '@/app/Constants'
import { required, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import ManageProgramUseCase from '../app/usecase'

export default {
  components: {
  },
  mixins: [validationMixin],
  data() {
    return {
      ENUM_ACTION,
      showModal: false,
      isSubmit: false,
      textConfirmation: '',
      isLoading: false,
      message: {
        success: 'program berhasil di buat',
        failed: 'program gagal di buat'
      },
      form: {
        name: '',
        description: '',
        initiatePrice: '',
      },
    }
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, typeMessage) {
      this.$notifications.notify({
        message: this.message[typeMessage],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    onModalActions(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModal = false
      }

      if(type === ENUM_ACTION.OKE) {
        this.editProgram(this.form, this.$route.query.packet_id)
      }
    },
    onChange() {},
    onActions(type) {
      if(type === ENUM_ACTION.BACK) {
        this.$router.go(-1)
      }

      if(type === ENUM_ACTION.SUBMIT) {
        this.textConfirmation = 'Apakah data sudah benar ?'
        this.showModal = true
      }

    },
    onMultipleUpload(value) {
      this.form.attachment = value[1]
    },
    onUploadPdf(value) {
      this.form.file = value[1]
    },
    async getProgram() {
      this.isLoading = true;
      const response = await ManageProgramUseCase.get(this.$route.query.packet_id);
      if (response && !response.error) {
        const {
          images,
          description,
          name,
          monthlyPrice,
          initiatePrice,
        } = response.data
        this.form = {
          attachment: images?.map((img) => img?.url),
          description,
          name,
          initiatePrice: initiatePrice?.replace('Rp', '')?.replace(',-', ''),
          monthlyPrice: monthlyPrice?.replace('Rp', '')?.replace(',-', '')
        }
      } else {
        this.notifyVue("top", "right", "danger", "failed");
      }

      this.isLoading = false;
    },
    async editProgram(data, id) {
      this.isLoading = true
      const payload = {
        name: data.name,
        description: data.description,
        monthly_price: Number(data.monthlyPrice?.replaceAll('.', '')),
      };

      const response = await ManageProgramUseCase.edit(payload, id)
      if(response && !response.error) {
        this.$router.go(-1);
        this.notifyVue('top', 'right', 'success', 'success')
      } else {
        this.notifyVue('top', 'right', 'danger', 'failed')
      }

      this.showModal = false
      this.isLoading = false
    },
  formattedNameFile(string) {
    return string.replaceAll('/', ' ').split(' ').pop()
  },
  },
  validations() {
    return {
      form: {
        name: {
          required
        },
        description: {
          required,
          minLength: minLength(20)
        },
        attachment: {
          minLength: minLength(1)
        },
      }
    }
  },
  created() {
    this.getProgram()
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/sass/lbd/variables';

.edit-program {
  width: 90%;
  background-color: white;
  margin: auto;
  padding: 20px;

  &__form {
    display: flex;
    flex-direction: column;

    > div:first-child {
      margin-bottom: 12px;
    }

    > div {
      margin-bottom: 32px;

      > label {
        color: $text-base;
        font-weight: 600;

        span {
          color: $danger-states-color;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      > div {
        margin-right: 12px;
      }
    }
  }
}

.text-confirmation {
  text-align: center;
  margin-bottom: 20px;
}

textarea {
  padding: 10px 20px;
  width: 100%;
  color: $text-base;
  border: 1px solid $light-gray;
  border-radius: 4px;

  &::placeholder {
    color: $light-gray;
  }
}

textarea:focus {
  outline: none;
  border: 1px solid $dark-gray;
}
</style>
