import { MapPagination } from '@/app/Mappers'

export const MapUser = (payload) => {
  return {
    id: payload.id,
    fullName: payload.full_name,
    email: payload.email,
    phoneNumber: payload.phone_number,
    role: payload.entity,
    position: payload.position,
    verifiedType: payload.verified_type,
    createdAt: payload.created_at,
    updatedAt: payload.updated_at
  }
}

export const MapUsers = (payload) => {
  return {
    pagination: MapPagination(payload.meta),
    data: payload.data
  }
}

export const MapPayloadUser = (payload) => {
  return {
    full_name: payload.fullName || payload.name,
    email: payload.email,
    phone_number: payload.phoneNumber || payload.phone,
    position: payload.position,
    password: payload.password,
    confirm_password: payload.password
  }
}
