<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <Card class="card-plain">
            <template slot="header">
              <div class="header">
                <div class="header__title">
                  <h4 class="card-title">Management Broadcast</h4>
                  <p class="card-category">Management content Broadcast</p>
                </div>
                <Button text="Tambah Broadcast" @click="() => {}" />
              </div>
            </template>
            <div class="form-filter">
              <BaseInput
                width="50"
                v-model="search"
                placeholder="Cari Berdasarkan nama"
              />
              <Button type="info" text="Cari" @click="() => {}" />
            </div>
            <div class="table-responsive">
              <l-table
                class="table-hover"
                :columns="table.columns"
                :data="table.data"
                @click="(e) => {}"
              >
              </l-table>
            </div>
          </Card>
        </div>
        <Loader v-show="isLoading" colorClass="l-gray" />
      </div>
      <Pagination
        :page="pagination.page"
        @prev="onClick('prev')"
        @next="onClick('next')"
      />
    </div>
  </div>
</template>

<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import Pagination from "@/components/Pagination/Pagination.vue";
import Loader from "@/components/Loader/Loader.vue";
import ManageBroadcastUseCase from './app/usecase/index'

export default {
  components: {
    LTable,
    Card,
    Pagination,
    BaseInput,
    Loader,
  },
  data() {
    return {
      table: {
        columns: [
          { label: "ID", value: "id", style: "width: 300px;" },
          { label: "Subjek", value: "subject" },
          { label: "Dibuat", value: "createdAt" },
          { label: "Diupdate", value: "updatedAt" },
        ],
        data: [],
      },
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1,
      },
      search: "",
      isLoading: false,
    };
  },
  methods: {
    onClick(type) {
      if (type === "prev" && this.pagination.page !== 1) {
        this.pagination.page--;
      }

      if (
        type === "next" &&
        this.pagination.page !== this.pagination.totalPage
      ) {
        this.pagination.page++;
      }

      this.getAdmin();
    },

    async getAllBroadcast() {
      this.isLoading = true

      const { data, error, pagination } = await ManageBroadcastUseCase.getAll()

      if(error === null) {
        this.table.data = data.data
        this.pagination = pagination
        console.log(data)
      } else {
        console.log(error)
      }

      this.isLoading = false
    }
  },
  created() {
    this.getAllBroadcast()
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.form-filter {
  display: flex;

  > div:first-child {
    margin-right: 20px;
  }
}
</style>
