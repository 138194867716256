<template>
  <div class="multiple-image-upload">
    <div
      v-for="(img, index) in images"
      :key="index"
      class="multiple-image-upload__box-image"
    >
      <img :src="typeof img === 'string' ? img : img.url" alt="image list" />
      <i class="nc-icon nc-simple-remove" @click="onClose(index)"></i>
    </div>
    <div
      v-if="images.length < maxImages"
      class="multiple-image-upload__box"
      @click="handleClick"
    >
      <i class="nc-icon nc-simple-add"></i>
      <input
        ref="file"
        v-bind="$attrs"
        accept="image/jpg, image/png, image/jpeg"
        type="file"
        style="display: none"
        @focus="$emit('focus', $event)"
        @change="onChange"
        @click="(event) => {
          event.target.value = ''
          $emit('click', event)
        }"
      />
    </div>
    <div v-if="error" class="multiple-image-upload__error">
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultipleImageUpload",
  props: {
    maxImages: {
      type: Number,
      default: 2,
    },
    maxSize: {
      type: Number,
      default: 5,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "gambar tidak boleh melebihi 5MB",
    },
    imageSrc: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      images: [],
      error: '',
      errorMessageImage: '',
      files: []
    };
  },
  model: {
    prop: 'imageSrc',
    event: ['value', 'close']
  },
  methods: {
    handleClick() {
      this.$refs.file.click();
    },
    onChange(event) {
      const maxSize = this.maxSize * 1024 * 1024;
      if (event.target.files[0].size >= maxSize) {
        this.error = true;
        this.errorMessageImage = `gambar tidak boleh melebihi ${this.maxSize}MB`;
      } else {
        const url = URL.createObjectURL(event.target.files[0]);
        this.images.push(url);
        this.files.push(event.target.files[0])
        this.$emit("value", [this.images, this.files]);
        this.error = false
      }
    },
    onClose(index) {
      this.images = this.images.filter((_, idx) => idx !== index);
      this.files = this.files.filter((_, idx) => idx !== index);
      if(this.images?.length === 0 && this.files?.length === 0) {
        this.$emit('close', [])
        return
      }
      this.$emit('close', [this.images, this.files])
    },
  },
  watch: {
    imageSrc(value) {      
      this.images = value
    },
    isError(value) {
      this.error = value
    },
    errorMessage(value) {
      this.errorMessageImage = value
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/lbd/_variables.scss";
.multiple-image-upload {
  display: flex;
  position: relative;

  > div {
    margin: 0 8px;
  }

  &__box {
    width: 120px;
    height: 120px;
    border-radius: 6px;
    border: 1px dashed $dark-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__box-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    > i {
      margin-bottom: 6px;
      position: absolute;
      top: -20px;
      cursor: pointer;
    }

    > img {
      object-fit: cover;
      border-radius: 6px;
      box-shadow: $box-shadow;
      width: 120px;
      height: 120px;
    }
  }

  &__error {
    position: absolute;
    top: 125px;
    font-size: 14px;
    color: $red-color-bottom;
  }
}
</style>
