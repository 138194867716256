import DashboardLayout from '../layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'

// Admin pages
import Dashboard from 'src/pages/Dashboard/Dashboard.vue'
import Profile from 'src/pages/Profile/Profile.vue'
import ManageUser from 'src/pages/ManageUser/ManageUser.vue'
import Typography from 'src/pages/Typography/Typography.vue'
import Icons from 'src/pages/IconsPage/Icons.vue'
import Notifications from 'src/pages/Notification/Notifications.vue'
import Login from 'src/pages/Login/Login.vue'
import ManageUserDetail from '@/pages/ManageUser/detail/ManageUserDetail.vue'
import ManageAdmin from '@/pages/ManageAdmin/ManageAdmin.vue'
import ManageSiswa from '@/pages/ManageSiswa/ManageSiswa.vue'
import ManageWali from '@/pages/ManageWali/ManageWali.vue'
import ManageEvent from '@/pages/ManageEvent/ManageEvent.vue'
import ManageEventEdit from '@/pages/ManageEvent/edit/ManageEventEdit.vue'
import ManageEventDetail from '@/pages/ManageEvent/detail/ManageEventDetail.vue'
import ManageEventCreate from '@/pages/ManageEvent/create/ManageEventCreate.vue'
import ManageArticle from '@/pages/ManageArticle/ManageArticle.vue'
import ManageProduct from '@/pages/ManageProduct/ManageProduct.vue'
import ManageTransactions from '@/pages/ManageTransactions/ManageTransactions.vue'
import ManageBroadcast from '@/pages/ManageBroadcast/ManageBroadcast.vue'
import VueRouter from 'vue-router'
import { getToken } from '../app/misc/Cookies'
import ManageAdminDetail from '@/pages/ManageAdmin/detail/ManageAdminDetail.vue'
import ManageSiswaDetail from '@/pages/ManageSiswa/detail/ManageSiswaDetail.vue'
import ManageWaliDetail from '@/pages/ManageWali/detail/ManageWaliDetail.vue'
import ManageArticleDetail from '@/pages/ManageArticle/detail/ManageArticleDetail.vue'
import ManageArticleCreate from '@/pages/ManageArticle/create/ManageArticleCreate.vue'
import ManageProductDetail from '@/pages/ManageProduct/detail/ManageProductDetail.vue'
import ManageProductCreate from '@/pages/ManageProduct/create/ManageProductCreate.vue'
import ManageProductEdit from '@/pages/ManageProduct/edit/ManageProductEdit.vue'
import ManageProgram from '@/pages/ManageProgram/ManageProgram.vue'
import ManageProgramDetail from '@/pages/ManageProgram/detail/ManageProgramDetail.vue'
import ManageProgramCreate from '@/pages/ManageProgram/create/ManageProgramCreate.vue'
import ManageProgramEdit from '@/pages/ManageProgram/edit/ManageProgramEdit.vue'
import ManageSubProgramCreate from '@/pages/ManageSubProgram/create/ManageSubProgramCreate.vue'
import ManageSubProgramEdit from '@/pages/ManageSubProgram/edit/ManageSubProgramEdit.vue'
import ManagePacketProgram from '@/pages/ManagePacketProgram/ManagePacketProgram.vue'
import ManagePacketProgramCreate from '@/pages/ManagePacketProgram/create/ManagePacketProgramCreate.vue'
import ManagePacketProgramEdit from '@/pages/ManagePacketProgram/edit/ManagePacketProgramEdit.vue'
import ManageSppPembayaran from '@/pages/ManageSppPembayaran/ManageSppPembayaran.vue'
import ManageSppPembayaranCreate from '@/pages/ManageSppPembayaran/create/ManageSppPembayaranCreate.vue'
import Configuration from '@/pages/Configuration/Configuration.vue'
import ManageArticleEdit from '@/pages/ManageArticle/edit/ManageArticleEdit.vue'

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'user',
        name: 'User',
        component: Profile,
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: '/management-user',
        component: {
          render: c => c(/* webpackChunkName: "ManagementUser" */ 'router-view')
        },
        children: [
          {path: '', component: ManageUser, name: 'Management User',},
          {path: ':id', component: ManageUserDetail, name: ':id'},
        ],
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'typography',
        name: 'Typography',
        component: Typography,
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'icons',
        name: 'Icons',
        component: Icons,
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications,
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'management-admin',
        component: {
          render: c => c(/* webpackChunkName: "ManagementAdmin" */ 'router-view')
        },
        children: [
          {path: '', component: ManageAdmin, name: 'Management Admin',},
          {path: ':id', component: ManageAdminDetail, name: ':id'},
        ],
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'management-siswa',
        component: {
          render: c => c(/* webpackChunkName: "ManagementAdmin" */ 'router-view')
        },
        children: [
          {path: '', component: ManageSiswa, name: 'Management Siswa',},
          {path: ':id', component: ManageSiswaDetail, name: ':id'},
        ],
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'management-wali',
        component: {
          render: c => c(/* webpackChunkName: "ManagementAdmin" */ 'router-view')
        },
        children: [
          {path: '', component: ManageWali, name: 'Management Wali',},
          {path: ':id', component: ManageWaliDetail, name: ':id'},
        ],
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'management-event',
        name: '',
        component: {
          render: c => c(/* webpackChunkName: "ManagementEvent" */ 'router-view')
        },
        children: [
          {path: '', component: ManageEvent, name: 'Management Event'},
          {path: 'detail/:id', component: ManageEventDetail, name: ':id'},
          {path: 'edit/:id', component: ManageEventEdit, name: ':id'},
          {path: 'create', component: ManageEventCreate, name: 'Tambah Event'}
        ],
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'management-article',
        component: {
          render: c => c(/* webpackChunkName: "ManagementArticle" */ 'router-view')
        },
        children: [
          {path: '', component: ManageArticle, name: 'Management Article'},
          {path: 'detail/:id', component: ManageArticleDetail, name: ':id'},
          {path: 'edit/:id', component: ManageArticleEdit, name: ':id'},
          {path: 'create', component: ManageArticleCreate, name: 'Tambah Artikel'}
        ],
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'management-product',
        component: {
          render: c => c(/* webpackChunkName: "ManagementUser" */ 'router-view')
        },
        children: [
          {path: '', component: ManageProduct, name: 'Management Product',},
          {path: 'detail/:id', component: ManageProductDetail, name: ':id'},
          {path: 'create', component: ManageProductCreate, name: 'Create'},
          {path: 'edit/:id/', component: ManageProductEdit, name: ':id'},
        ],
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'management-transactions',
        name: 'Management Transactions',
        component: ManageTransactions,
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'management-broadcast',
        name: 'Management Broadcast',
        component: ManageBroadcast,
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: 'management-program',
        component: {
          render: c => c(/* webpackChunkName: "ManagementUser" */ 'router-view')
        },
        children: [
          {path: '', component: ManageProgram, name: 'Management Program'},
          {path: 'detail/packet/:id', component: ManagePacketProgram, name: 'Management Packet Program'},
          {path: 'detail/packet/:id/create', component: ManagePacketProgramCreate, name: 'Form Packet Program'},
          {path: 'detail/packet/:id/edit', component: ManagePacketProgramEdit, name: 'Form Packet Program'},
          {path: 'detail/:id', component: ManageProgramDetail, name: ':id'},
          {path: 'detail/:id/subprogram', component: ManageSubProgramCreate, name: 'Management Sub Program'},
          {path: 'detail/:programId/subprogram/edit/:subProgramId/', component: ManageSubProgramEdit, name: 'Edit Sub Program'},
          {path: 'create', component: ManageProgramCreate, name: 'Create'},
          {path: 'edit/:id/', component: ManageProgramEdit, name: ':id'},
        ],
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: '/spp-pembayaran',
        component: {
          render: c => c(/* webpackChunkName: "ManagementUser" */ 'router-view')
        },
        children: [
          {path: '', component: ManageSppPembayaran, name: 'Management SPP Pembayaran'},
          {path: 'create', component: ManageSppPembayaranCreate, name: 'Create'},
        ],
        meta: {
          auth: true,
          type: ''
        }
      },
      {
        path: '/configuration',
        component: {
          render: c => c(/* webpackChunkName: "ManagementUser" */ 'router-view')
        },
        children: [
          { path: '', component: Configuration, name: 'Management Akun Bank' },
          { path: 'spp-due-date', component: Configuration, name: 'Management SPP Due Date' },
        ],
        meta: {
          auth: true,
          type: ''
        }
      },
    ],
    meta : {
      auth: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  { path: '*', component: NotFound }
]


const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "active",
  routes
})

const checkAuth = () => {
  const accessToken = getToken()
  if(accessToken) return true;
  else return false;
}

router.beforeEach(async (to, from, next) => {
  const isAuth = checkAuth()
  if(!isAuth && to.name !== 'Login') next({ name: 'Login' })
  else if (to.path === '/') next({ name: 'Dashboard' })
  else next()
})

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default router
