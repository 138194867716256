import CMSInternalAPI from '@/app/BaseAxios'
import {MapBroadcasts} from '../mappers/index'

export default class ManageBroadcastRepository extends CMSInternalAPI {
  constructor() {
    super()

    /** @type { string } */
    this.endpoint = '/v1/admin/management-email-broadcast'
  }

  /**
   *
   * @param { string } page
   * @param { string } perPage
   * @param { string } serach
   */

  async getAll(page = '1', perPage = '10', search = '') {
    try {
      /** @type { import("axios").AxiosResponse<Object> } */
      const response = await this.$axios.get(`${this.endpoint}/list?page=${page}&limit=${perPage}&subject=${search}`)
      if(response && response.status === 200) {
        return { data: MapBroadcasts(response.data), error: null }
      }
      return { data: null, error: null }
    } catch (error) {
      return { data: null, error: this.setErrorMessage(error.response?.data) }
    }
  }
}
