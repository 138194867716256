<template>
  <div>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Dashboard</a>
      <button type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{toggled: $sidebar.showSidebar}"
              aria-controls="navigation-index"
              aria-expanded="false"
              aria-label="Toggle navigation"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="#">
              Akun Name
            </a>
          </li>
          <base-dropdown title="Dropdown">
            <a class="dropdown-item" href="#">Action</a>
          </base-dropdown>
          <li class="nav-item" @click="logout()">
            <a href="/" class="nav-link">
              Log out
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div v-if="pathName !== '/configuration'">
    <Breadcrumb :path="routeName" />
  </div>
</div>
</template>
<script>
import Breadcrumb from '../components/Breadcrumb/Breadcrumb.vue'
import { removeToken } from '@/app/misc/Cookies.js'

  export default {
    computed: {
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },
        pathName() {
          const { path } = this.$route;
          return path;
        },
    },
    data() {
        return {
            activeNotifications: false
        };
    },
    methods: {
        capitalizeFirstLetter(string) {
            if (Boolean(string)) string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        logout() {
          removeToken()
        }
    },
    components: { Breadcrumb },
}

</script>
<style>

</style>
