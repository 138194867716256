import { MapPagination } from '@/app/Mappers'
import { TimeMapper } from '@/app/utils'

export const MapArticles = (payload) => {
  return {
    pagination: MapPagination(payload.meta),
    data: payload.data
  }
}

export const MapArticle = (payload) => {
  return {
    id: payload.id,
    title: payload.title,
    slug: payload.slug,
    createdAt: TimeMapper(payload.created_at, false),
    updatedAt: TimeMapper(payload.updated_at, false),
    attachment: payload.images,
    description: payload.description,
    category: payload.category,
    highlight: payload?.highlight,
    attachmentThumbnails: payload.image_thumbnails
  }
}
