<template>
  <div class="program">
    <Loader v-show="isLoading" colorClass="l-gray" />
    <BackComponent />
    <div v-if="data" class="program__detail">
      <div class="program__detail__image">
        <img :src="imageProgram" alt="" />
        <!-- <Button type="success" text="Download" @click="handleDownload()" /> -->
      </div>
      <div class="program__detail__desc">
        <label>Nama:</label>
        <p>{{ data.name }}</p>
        <label>Biaya Pendaftaran:</label>
        <p>{{ data.initiatePrice }}</p>
        <label>Biaya Tahunan (Min.50%):</label>
        <p>{{ data.yearlyPrice }}</p>
        <label>Dibuat:</label>
        <p>{{ data.createdAt }}</p>
        <label>Diubah:</label>
        <p>{{ data.updatedAt }}</p>
      </div>
    </div>
    
    <!-- MODAL PROGRAM -->
    <Modal
      :showModal="showModalDelete"
      :isConfirmation="true"
      :useHeader="false"
      width="28"
      @cancel="onModalActions(ENUM_ACTION.CANCEL)"
      @oke="onModalActions(ENUM_ACTION.OKE)"
    >
      <div class="text-confirmation">
        <h4>{{ textConfirmation }}</h4>
      </div>
    </Modal>
    
    <!-- MODAL SUB PROGRAM -->
    <Modal
      :showModal="showModalDeleteSubProgram"
      :isConfirmation="true"
      :useHeader="false"
      width="28"
      @cancel="onSubProgramModalActions(ENUM_ACTION.CANCEL)"
      @oke="onSubProgramModalActions(ENUM_ACTION.OKE)"
    >
      <div class="text-confirmation">
        <h4>{{ textConfirmation }}</h4>
      </div>
    </Modal>
    
    <div class="program__detail__actions">
      <button class="btn-packet" @click="onPacketProgramNavigate()" v-show="!(data.subProgram.length > 0)">
        Paket
      </button>
      <Button text="Edit" @click="onActions(ENUM_ACTION.EDIT)"/>
      <Button text="Hapus" type="danger" @click="onActions(ENUM_ACTION.DELETE)"/>
    </div>
    <hr class="program__separator">
    <div class="program__sub-program">
      <div class="program__sub-program__title">
        <h3>Sub Program</h3>
        <Button text="Tambah Sub Program" @click="navigateToFormAddSubProgram()"/>
      </div>
      
      <div class="table-responsive">
        <l-table
          class="table-hover"
          :isShowActions="true"
          :columns="table.columns"
          :data="data.subProgram"
          @click="{}"
        >
          <template v-slot:actions="slotProps">
            <td>
              <button 
                class="table-item-action table-item-action__paket" 
                @click="onSubProgramActions(ENUM_ACTION.DETAIL, slotProps.id)"
              >
                <i class="fa fa-archive fa-md"></i>
              </button> |
              <button 
                class="table-item-action table-item-action__edit" 
                @click="onSubProgramActions(ENUM_ACTION.EDIT, slotProps.id)"
              >
                <i class="fa fa-pencil-square-o fa-md"></i>
              </button> |
              <button 
                class="table-item-action table-item-action__delete" 
                @click="onSubProgramActions(ENUM_ACTION.DELETE, slotProps.id)"
              >
                <i class="fa fa-trash fa-md"></i>
              </button>
            </td>
          </template>
        </l-table>
      </div>
    </div>
  </div>
</template>

<script>
import LTable from "src/components/Table.vue";
import ManageProgramUseCase from "../app/usecase";
import ManagSubProgramUseCase from "../../ManageSubProgram/app/usecase";
import Loader from "@/components/Loader/Loader.vue";
import { ENUM_ACTION } from '@/app/Constants'

export default {
  components: { 
    Loader,
    LTable,
  },
  data() {
    return {
      ENUM_ACTION,
      table: {
        columns: [
          { label: "Nama", value: "name" },
          { label: "Biaya Pendaftaran", value: "initiatePrice" },
          { label: "Biaya Tahunan (Min. 50℅)", value: "yearlyPrice" },
          { label: "Dibuat", value: "createdAt" },
        ],
      },
      data: {
        id: null,
        name: null,
        slug: null,
        description: null,
        initiatePrice: null,
        yearlyPrice: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
        subProgram: [],
        images: [],
      },
      imageProgram: '',
      isLoading: false,
      showModal: false,
      showModalDelete: false,
      showModalDeleteSubProgram: false,
      selectedSubProgramId: null,
      textConfirmation: '',
      errorMessage: {
        response: null,
      },
      message: {
        empty: "Data anda tidak boleh kosong",
        email: "Email anda tidak valid",
        name: "nama tidak boleh angka",
        password: "Password harus lebih dari 8 huruf",
        phone: "Nomor handphone setidak nya 9 angka sampai 15 angka",
        address: "Pastikan alamat diisi dengan benar",
        response: 'Gagal Memuat data'
      },
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, item) {
      this.$notifications.notify({
        message: this.message[item],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    async getProgram() {
      this.isLoading = true;
      const response = await ManageProgramUseCase.get(this.$route.query.id);
      if (response && !response.error) {
        this.data = response.data;
        this.imageProgram = response.data?.images?.find((img) => img?.imageableModel)?.url || ''
      } else {
        this.notifyVue("top", "right", "danger", "failed");
      }

      this.isLoading = false;
    },
    // async handleDownload() {
    //     const url = this.data.files[0].url;
    //     const el = document.createElement('a')
    //     el.target = '_blank'
    //     el.href = url
    //     el.click()
    // },
    onActions(type) {
      if(type === ENUM_ACTION.DELETE) {
        this.textConfirmation = 'Apakah anda yakin ingin menghapus data tersebut ?'
        this.showModalDelete = true
      }

      if(type === ENUM_ACTION.EDIT) {
        const path = `/management-program/edit/${this.$route.params.id}?id=${this.$route.query.id}`
        this.$router.push(path)
      }
    },
    onModalActions(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModalDelete = false
      }

      if(type === ENUM_ACTION.OKE) {
        this.deleteProgram()
      }
    },
    async deleteProgram() {
      this.isLoading = true
      const response = await ManageProgramUseCase.delete(this.$route.query.id)
      if(response && !response.error) {
        this.errorMessage.response = "Success menghapus data"
        this.notifyVue("top", "right", "success", "response")
        this.$router.push('/management-program')
      } else {
        this.errorMessage.response = "Gagal menghapus data"
        this.notifyVue("top", "right", "danger", "response")
      }

      this.isLoading = false
    },
    navigateToFormAddSubProgram() {
      this.$router.push(`${this.$route.path}/subprogram?id=${this.data.id}`)
    },
    onPacketProgramNavigate() {
      const path = `packet/${this.data.id}?program_id=${this.data.id}&program_type=program`
      this.$router.push(path)
    },
    onSubProgramActions(type, subProgramId) {
      if (type === ENUM_ACTION.DETAIL) {
        const path = `packet/${subProgramId}?program_id=${subProgramId}&program_type=subprogram`
        this.$router.push(path)
      }
      
      if (type === ENUM_ACTION.DELETE) {
        this.textConfirmation = 'Apakah anda yakin ingin menghapus data tersebut ?'
        this.showModalDeleteSubProgram = true
        this.selectedSubProgramId = subProgramId
      }

      if (type === ENUM_ACTION.EDIT) {
        const path = `${this.$route.params.id}/subprogram/edit/${subProgramId}`
        this.$router.push(path)
      }
    },
    onSubProgramModalActions(type) {
      if(type === ENUM_ACTION.CANCEL) {
        this.showModalDeleteSubProgram = false
      }

      if(type === ENUM_ACTION.OKE) {
        this.deleteSubProgram(this.selectedSubProgramId)
      }
    },
    async deleteSubProgram(id) {
      this.isLoading = true
      const response = await ManagSubProgramUseCase.delete(id)
      
      if(response && !response.error) {
        this.message.response = "Success menghapus data"
        this.notifyVue("top", "right", "success", "response")
        this.getProgram();
      } else {
        this.errorMessage.response = "Gagal menghapus data"
        this.notifyVue("top", "right", "danger", "response")
      }
      
      this.showModalDeleteSubProgram = false
      this.isLoading = false
    },
  },
  created() {
    this.getProgram();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/sass/lbd/variables";
.program {
  background-color: white;
  width: 90%;
  margin: 20px auto;
  margin-bottom: 20px;
  border-radius: 16px;
  box-shadow: $box-shadow;
  padding: 6px 20px;

  &__detail {
    display: flex;
    justify-content: space-around;
    color: $text-base;
    margin-top: 58px;

    > div:first-child {
      margin-right: 32px;
    }

    > div:last-child {
      width: 50%;
      height: 100%;
    }

    &__image {
      display: flex;
      flex-direction: column;
      align-items: center;

      > img {
        width: 450px;
        height: 300px;
        margin-bottom: 32px;
        box-shadow: $box-shadow-smooth;
        border-radius: 8px;
        object-fit: cover;
      }
    }

    &__desc {
      display: grid;
      grid-template-columns: 0.5fr 2fr;

      > label {
        font-weight: 600;
      }
    }
    
    &__actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 52px;
      gap: 20px;
      
      .btn-packet {
        border: 0;
        color: white;
        font-weight: bold;
        background-color: #4f8904;
        width: 120px;
        border-radius: 4px;
      }
    }
  }
  
  &__separator {
    margin: 40px auto;
  }
  
  &__sub-program {
    padding-bottom: 60px;
    
    &__title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      
      h3 {
        font-weight: 500;
        margin-bottom: 20px;
      }
    }
  }
}

.table-item-action {
  display: inline-block;
  width: 36px;
  height: 36px;
  color: white;
  font-weight: bold;
  line-height: 36px;
  border-radius: 6px;
  box-sizing: border-box;
  border: none;

  &__paket {
    background-color: #4f8904;
  }
  
  &__edit {
    background-color: #1365b3;
  }
  
  &__delete {
    background-color: #d11313;
  }
}
</style>
