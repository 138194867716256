import { MapPagination } from '@/app/Mappers'
import { TimeMapper } from '@/app/utils'
import { formatCurrancy } from '../../../../app/utils'

export const MapPrograms = (response) => {
  return {
    pagination: MapPagination(response.meta),
    data: response.data
  }
}

export const MapProgramImages = (response) => {
  if (response?.length < 1) return []
  return response?.map((img) => {
    return {
      id: img?.id,
      imageableId: img?.imageable_id,
      imageableModel: img?.imageable_model,
      url: img?.url,
      path: img?.path,
      createdAt: TimeMapper(img?.created_at),
      updatedAt: TimeMapper(img?.updated_at)
    }
  })
}

export const MapSubPrograms = (response) => {
  if (response?.length < 1) return []
  return response?.map((resp) => {
    return {
      id: resp?.id,
      name: resp?.name,
      slug: resp?.slug,
      description: resp?.description,
      initiatePrice: resp?.initiate_price ? formatCurrancy(resp?.initiate_price) : 'Rp. 0',
      yearlyPrice: resp?.yearly_price ? formatCurrancy(resp?.yearly_price) : 'Rp. 0',
      createdAt: TimeMapper(resp?.created_at),
      updatedAt: TimeMapper(resp?.updated_at),
      deletedAt: TimeMapper(resp?.deleted_at),
      images: MapProgramImages(resp?.images)
    }
  })
}

export const MapProgram = (response) => {
  return {
    id: response?.id,
    name: response?.name,
    slug: response?.slug,
    description: response?.description,
    initiatePrice: response?.initiate_price ? formatCurrancy(response?.initiate_price) : 'Rp. 0',
    yearlyPrice: response?.yearly_price ? formatCurrancy(response?.yearly_price) : 'Rp. 0',
    createdAt: TimeMapper(response?.created_at),
    updatedAt: TimeMapper(response?.updated_at),
    deletedAt: TimeMapper(response?.deleted_at),
    subProgram: MapSubPrograms(response?.sub_program),
    images: MapProgramImages(response?.images)
  }
}
