import { MapPagination } from '@/app/Mappers';

export const MapGetList = (payload) => {
  return {
    data: payload.data.map((item) => ({
      id: item.id || null,
      invoiceNumber: item.invoice_number || null,
      studentFullName: item.student_full_name || null,
      dueDate: item.due_date || null,
      transactionStatus: item.transaction_status || null,
    })),
    pagination: MapPagination(payload.meta),
  };
};

export const MapStudenWithParent = (payload) => {
  return {
    data: payload.data.map((item) => ({
      studentId: item.student_id || null,
      studentWithParent: item.student_with_parents || null,
    })),
  };
};

export const MapProgramByStudent = (payload) => {
  return {
    data: Array.isArray(payload.data?.programs) ? payload.data.programs.map((item) => ({
      programId: item.program_id || null,
      programName: item.program_name || null,
      programType: item.program_type || null,
      subPrograms: item.sub_programs ? item.sub_programs.map ((item) => ({
        programId: item.program_id || null,
        programName: item.program_name || null,
        programType: item.program_type || null,
      })) : [],
    })) : [],
  }
};

export const MapPacketProgram = (payload) => {
  return {
    data: Array.isArray(payload.data) ? payload.data.map((item) => ({
      packetId: item.id || null,
      packetName: item.name || null,
      packetPrice: item.monthly_price || null,
    })) : [],
  };
}

export const MapPayloadCreate = (payload) => {
  return {
    full_name: payload.fullName,
    gender: payload.gender,
    place_of_birth: payload.placeOfBirth,
    dob: payload.dob,
    religion: payload.religion,
    address: payload.address,
    previous_school: payload.previousSchool,
    is_disability: payload.isDisability,
  }
}

export const MapSppDueDate = (payload) => {
  return {
    id: payload.id,
    dueDate: payload.due_date,
    createdAt: payload.created_at,
    updatedAt: payload.updated_at
  }
}

export const MapSppDueDates = (payload) => {
  return {
    data: payload.data
  }
}
