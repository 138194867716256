<template>
  <div class="content">
    <div class="container-fluid">
      <ul class="nav nav-tabs">
        <li class="nav-item" :class="{ active: pathName === '/configuration' }">
          <router-link to="/configuration" class="nav-link">Manage Akun Bank</router-link>
        </li>
      </ul>
      
      <div v-if="pathName === '/configuration'">
        <ManageAccountBank />
      </div>
    </div>
  </div>  
</template>

<script>
import ManageAccountBank from './ManageAccountBank/ManageAccountBank.vue';

export default {
  components: {
    ManageAccountBank,
  },
  data() {
    return {
    };
  },
  computed: {
    pathName() {
      const { path } = this.$route;
      return path;
    }
  },
  methods: {
    
  },
  created() {
    
  }
};
</script>

<style lang="scss" scoped>
  .nav-item {
    background-color: rgba(203, 203, 210, 0.15);;
  }
  
  .nav-item.active {
    background-color: #fff;
  }
  
  .nav-item a {
    color: #495057;
  }
</style>
