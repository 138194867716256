export const MapUser = (payload) => {
  return {
    id: payload.id,
    bankAccountName: payload.bank_account_name,
    bankAccountNumber: payload.bank_account_number,
    bankAccountRecipient: payload.bank_account_recipient,
    createdAt: payload.created_at,
    updatedAt: payload.updated_at
  }
}

export const MapUsers = (payload) => {
  return {
    data: payload.data
  }
}

export const MapPayloadUser = (payload) => {
  return {
    bank_account_name: payload.bankAccountName,
    bank_account_number: payload.bankAccountNumber,
    bank_account_recipient: payload.bankAccountRecipient,
  }
}
