<template>
  <div
    class="form-group"
    :class="{
      'input-group': hasIcon,
      'input-group-focus': focused,
    }"
    :style="`width: ${width}%`"
  >
    <slot name="label">
      <label v-if="label" class="control-label">
        {{ label }}
      </label>
    </slot>
    <slot name="addonLeft">
      <span v-if="addonLeftIcon" class="input-group-prepend">
        <div class="input-group-text">
          <i :class="addonLeftIcon">{{
            Boolean(addonLeftIcon.includes("nc")) ? "" : addonLeftIcon
          }}</i>
        </div>
      </span>
    </slot>
    <slot>
      <input
        :value="value"
        v-bind="$attrs"
        :type="type"
        v-on="listeners"
        class="form-control"
        maxlength="200"
        aria-describedby="addon-right addon-left"
        @change="$emit('change', value)"
      />
      <span>{{ errorMessage }}</span>
    </slot>
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-append">
        <div
          class="input-group-text"
          style="cursor: pointer"
          @click="$emit('showHide')"
        >
          <i :class="addonRightIcon"></i>
        </div>
      </span>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
import { currencyDigit } from "@/app/utils";

export default {
  inheritAttrs: false,
  name: "BaseInput",
  props: {
    label: {
      type: String,
      description: "Input label",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    addonRightIcon: {
      type: String,
      description: "Input icon on the right",
    },
    addonLeftIcon: {
      type: String,
      description: "Input icon on the left",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "auto",
    },
    type: {
      type: String,
      default: "text",
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
  },
  methods: {
    onInput(event) {
      if (this.type === "currency") {
        const newVal = currencyDigit(
          Number(event.target.value.replaceAll(".", "")),
          ''
        );
        this.$emit("input", newVal);
        return;
      }
      this.$emit("input", event.target.value);
    },
    onFocus(event) {
      this.$emit("focus", event);
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.input-group {
  height: fit-content;
}
</style>
