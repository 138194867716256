<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <div class="header">
                <div class="header__title">
                  <h4 class="card-title">Management Event</h4>
                  <p class="card-category">Management Event</p>
                </div>
                <Button text="Tambah Event" @click="navigateToCreate" />
              </div>
            </template>
            <div class="form-filter">
              <BaseInput
                width="50"
                v-model="search"
                placeholder="Cari Berdasarkan Title"
              />
              <Button type="info" text="Cari" @click="getEventList" />
            </div>
            <div class="table-responsive">
              <l-table
                class="table-hover"
                :columns="table.columns"
                :data="table.data"
                @click="(e) => navigateToDetail(e)"
              >
              </l-table>
            </div>
          </card>
        </div>
        <Loader v-show="isLoading" colorClass="l-gray" />
        <!-- open Modal -->
        <Modal
          :showModal="showModal"
          :useHeader="true"
          :isConfirmation="true"
          textHeader="Tambah Event Form"
          @cancel="() => {}"
          @oke="() => {}"
          textButton="Simpan"
          top="10"
        >
          <template>
            <div class="modal-event">
              <label for="nama">Nama</label>
              <BaseInput
                placeholder="Masukan nama"
                type="text"
                v-model="form.name"
                :errorMessage="error.name"
                required
              />
              <label for="email">Email</label>
              <BaseInput
                placeholder="Masukan email"
                type="email"
                v-model="form.email"
              />
              <label for="phone">Nomor Handphone</label>
              <BaseInput
                addonLeftIcon="+62"
                placeholder="Masukan nomor handphone"
                type="number"
                :value="form.phone"
                @input="(value) => {}"
                required
              />
              <label for="address">Alamat</label>
              <BaseInput
                placeholder="Masukan alamat"
                type="text"
                v-model="form.address"
                required
              />
            </div>
          </template>
        </Modal>
      </div>
      <Pagination
        :page="pagination.page"
        :totalPage="pagination.totalPage"
        @prev="paginationActions(ENUM_ACTION.PREV)"
        @next="paginationActions(ENUM_ACTION.NEXT)"
      />
    </div>
  </div>
</template>

<script>
import LTable from "src/components/Table.vue";
import Card from "src/components/Cards/Card.vue";
import ManageEventUseCase from "./app/usecase";
import { ENUM_ACTION } from "@/app/Constants";

export default {
  components: {
    LTable,
    Card,
  },
  data() {
    return {
      ENUM_ACTION,
      table: {
        columns: [
        { label: "Title", value: "title" },
        { label: "Kategori", value: "category" },
        { label: "Tanggal di selenggarakan", value: "dateOfEvent" },
        { label: "Dibuat", value: "createdAt" },
        { label: "Diubah", value: "updatedAt" },
        ],
      },
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1,
      },
      isLoading: false,
      showModal: false,
      type: "text",
      form: {
        name: "",
        email: "",
        phone: "",
        address: "",
        password: "",
      },
      search: "",
      error: [],
      notifications: {
        topCenter: false,
      },
      errorMessage: {
        empty: "Data anda tidak boleh kosong",
        email: "Email anda tidak valid",
        name: "nama tidak boleh angka",
        password: "Password harus lebih dari 8 huruf",
        phone: "Nomor handphone setidak nya 9 angka sampai 15 angka",
        address: "Pastikan alamat diisi dengan benar",
      },
    };
  },
  methods: {
    notifyVue(verticalAlign, horizontalAlign, color, item) {
      this.$notifications.notify({
        message: this.errorMessage[item],
        icon: "nc-icon nc-app",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: color,
      });
    },
    async getEventList() {
      this.isLoading = true;
      const response = await ManageEventUseCase.getAll(
        this.pagination.page,
        this.pagination.perPage,
        this.search
      );
      if (response && !response.error) {
        this.table = {
          ...this.table,
          data: response.data.data
        };
        this.pagination = {
          page: response.data.pagination.page,
          perPage: response.data.pagination.perPage,
          totalPage: response.data.pagination.totalPage
        }
      } else {
        this.notifyVue("top", "right", "danger", "failed");
      }

      this.isLoading = false;
    },
    navigateToDetail(event) {
      this.$router.push(
        `${this.$route.path}/detail/${event.title}?id=${event.id}`
      );
    },
    navigateToCreate() {
      this.$router.push(`${this.$route.path}/create`);
    },
    paginationActions(type) {
      if (
        type === ENUM_ACTION.NEXT &&
        this.pagination.page !== this.pagination.totalPage
      ) {
        this.pagination.page++;
      }

      if (
          type === ENUM_ACTION.PREV &&
          this.pagination.page > 1
        ) {
        this.pagination.page--;
      }

      this.getEventList();
    },
  },
  created() {
    this.getEventList();
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.form-filter {
  display: flex;

  > div:first-child {
    margin-right: 20px;
  }
}
</style>
