<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="card-plain">
            <template slot="header">
              <div class="header">
                <div class="header__title">
                  <h4 class="card-title">Management SPP Pembayaran</h4>
                  <p class="card-category">Management SPP Pembayaran</p>
                </div>
                <div class="d-flex">
                  <Button 
                    className="btn-due-date mr-2 bg-info" 
                    text="Set Tenggat Waktu" 
                    @click="handleShowModalSppDueDate()" 
                  />
                  <Button 
                    text="Tambah SPP Pembayaran" 
                    @click="handleNavigateToForm()" 
                  />
                </div>
              </div>
            </template>
            <div class="form-filter-group">
              <div class="form-filter">
                <BaseInput width="100" v-model="search" placeholder="Cari Berdasarkan nama" />
                <Button type="info" text="Cari" @click="handleSearch" />
              </div>
              <Dropdown
                placeholder="Select Status"
                :options="dropdownStatusItems"
                @input="(value) => handleFilterStatus(value)"
              />
            </div>
            <div class="table-responsive">
              <Table
                class="table-hover"
                :columns="table.columns"
                :data="table.data"
                :sortFunction="(value) => handleSortByStatus(value)" 
              >
              </Table>
            </div>
          </card>
        </div>
        <Loader v-show="isLoading" colorClass="l-gray" />
        
        <!-- open Modal -->
        <Modal
          :showModal="showModalSppDueDate"
          :useHeader="true"
          :isConfirmation="true"
          :textHeader="modaltitleSppDueDate"
          :textButton="modalButtonTitleSppDueDate"
          @cancel="handleModalSppDueDateAction('CANCEL')"
          @oke="handleModalSppDueDateAction('SUBMIT')"
          top="10"
        >
          <template>
            <div class="modal-user">
              <label for="dueDate">Tenggat Waktu</label>
              <BaseInput
                placeholder="Masukan tanggal tenggat"
                type="date"
                v-model="form.sppDueDate"
                required
              />
            </div>
          </template>
        </Modal>
        
      </div>
      <Pagination
        :page="pagination.page"
        @prev="handleClickPagination('prev')"
        @next="handleClickPagination('next')"
      />
    </div>
  </div>
</template>

<script>
import { TimeMapper } from '@/app/utils';
import Table from "src/components/Table.vue";
import Modal from '@/components/Modal/Modal.vue';
import Card from "src/components/Cards/Card.vue";
import Button from "@/components/Button/Button.vue";
import Loader from '@/components/Loader/Loader.vue';
import Dropdown from "@/components/Dropdown/Dropdown.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import Pagination from "@/components/Pagination/Pagination.vue";

import ManageSppPaymentUseCase from './app/usecase';

export default {
  components: {
    Card,
    Table,
    Modal,
    Button,
    Loader,
    Dropdown,
    BaseInput,
    Pagination,
  },
  data() {
    return {
      table: {
        columns: [
          { label: 'Nomor Invoice', value: 'invoiceNumber' },
          { label: 'Nama Anak - Orang Tua (ibu)/Wali', value: 'studentFullName' },
          { label: 'Tanggal Deadline', value: 'dueDate' },
          { label: 'Status', value: 'transactionStatus' },
        ],
        data: []
      },
      form: {
        name: "",
        email: "",
        phone: "",
        address: "",
        password: "",
        sppDueDate: "",
      },
      isLoading: false,
      type: 'text',
      search: '',
      pagination: {
        page: 1,
        perPage: 10,
        totalPage: 1
      },
      sppDueDateCurrentValue: null,
      showModalSppDueDate: false,
      modaltitleSppDueDate: 'Set Tenggat Waktu Pembayaran SPP',
      modalButtonTitleSppDueDate: 'Simpan',
      selectedStatus: '',
      orderType: 'DESC',
      dropdownStatusItems: [
        { label: 'Waiting Verification', value: 'Waiting Verification' },
        { label: 'Processed', value: 'Processed' },
        { label: 'Rejected', value: 'Rejected' },
        { label: 'Cancel', value: 'Cancel' },
        { label: 'Done', value: 'Done' },
      ]
    };
  },
  methods: {
    handleNavigateToForm() {
      this.$router.push(`${this.$route.path}/create`);
    },
    async handleGetList() {
      this.isLoading = true
      const response = await ManageSppPaymentUseCase.getAll(
        this.pagination.page, 
        this.pagination.perPage, 
        this.search, 
        this.selectedStatus,
        this.orderType,
      );
      
      if (response && !response.error) {
        this.table = {
          ...this.table,
          data: response.data.data.map((item) => {
            return {
              id: item.id,
              invoiceNumber: item.invoiceNumber,
              studentFullName: item.studentFullName,
              dueDate: TimeMapper(item.dueDate),
              transactionStatus: item.transactionStatus,
            };
          }),
        };
        this.pagination = response.pagination
      } else {
        this.$notifications.notify({
          message: 'Gagal memuat data',
          icon: "nc-icon nc-app",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger',
        })
      }
      this.isLoading = false
    },
    async handleGetSppDueDate() {
      const response = await ManageSppPaymentUseCase.getSppDueDate()
      
      if (response && !response.error) {
        const responseData = response.data;
        const sppDueDate = responseData.data.length > 0 ? responseData.data[0] : '';
        
        if (sppDueDate) {
          this.sppDueDateCurrentValue = sppDueDate.id;
          this.form.sppDueDate = sppDueDate.due_date;
        }
      }
    },
    handleClickPagination(type) {
      if (type === "prev" && this.pagination.page !== 1) {
        this.pagination.page--;
      }

      if (type === "next" && this.pagination.page !== this.pagination.totalPage) {
        this.pagination.page++;
      }

      this.handleGetList()
    },
    handleSearch() {
      this.pagination.page = 1
      this.handleGetList()
    },
    handleShowModalSppDueDate() {
      this.showModalSppDueDate = true;
    },
    async handleModalSppDueDateAction(type) {
      if (type === 'SUBMIT') {
        this.isLoading = true;
        if (this.sppDueDateCurrentValue) {
          await ManageSppPaymentUseCase.updateSppDueDate(this.sppDueDateCurrentValue, {
            due_date: this.form.sppDueDate,
          });
        } else {
          await ManageSppPaymentUseCase.createSppDueDate({
            due_date: this.form.sppDueDate,
          });
        }
        
        this.$notifications.notify({
          message: 'Sukses menyimpan data tenggat waktu spp',
          icon: "nc-icon nc-app",
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success',
        })
      
        this.showModalSppDueDate = false;
        this.isLoading = false;
      } else {
        this.showModalSppDueDate = false;
      }
    },
    handleFilterStatus(value) {
      this.pagination = 1;
      this.selectedStatus = value;
      this.handleGetList();
    },
    handleSortByStatus(value) {
      this.orderType = value === 'up' ? 'DESC' : 'ASC';
      this.handleGetList();
    },
  },
  created() {
    this.handleGetList();
    this.handleGetSppDueDate();
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}

.form-filter-group {
  display: flex;
  gap: 40px;
  
  > div:first-child {
    flex-basis: 480px;
  }
}

.form-filter {
  display: flex;

  > div:first-child {
    margin-right: 20px;
  }
}
</style>
