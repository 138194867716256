import BaseInput from './components/Inputs/BaseInput.vue'
import BaseCheckbox from './components/Inputs/BaseCheckbox.vue'
import BaseRadio from './components/Inputs/BaseRadio.vue'
import BaseDropdown from './components/BaseDropdown.vue'
import Card from './components/Cards/Card.vue'
import Modal from './components/Modal/Modal.vue'
import Button from './components/Button/Button.vue'
import Loader from './components/Loader/Loader.vue'
import Dropdown from './components/Dropdown/Dropdown.vue'
import Pagination from './components/Pagination/Pagination.vue'
import ImageUpload from './components/ImageUpload/ImageUpload.vue'
import FileUpload from './components/FileUpload/FileUpload.vue'
import MultipleImage from './components/MultipleImage/MultipleImage.vue'
import BackComponent from './components/Back/Back.vue'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(Card.name, Card)
    Vue.component(Modal.name, Modal)
    Vue.component(Button.name, Button)
    Vue.component(Loader.name, Loader)
    Vue.component(Dropdown.name, Dropdown)
    Vue.component(Pagination.name, Pagination)
    Vue.component(ImageUpload.name, ImageUpload)
    Vue.component(FileUpload.name, FileUpload)
    Vue.component(MultipleImage.name, MultipleImage)
    Vue.component(BackComponent.name, BackComponent)
  }
}

export default GlobalComponents
