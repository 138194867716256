import { MapPagination } from '@/app/Mappers'
import { TimeMapper } from '@/app/utils'
import { formatCurrancy } from '../../../../app/utils'

export const MapProducts = (payload) => {
  return {
    pagination: MapPagination(payload.meta),
    data: payload.data
  }
}

export const MapProduct = (payload) => {
  return {
    id: payload?.id,
    name: payload?.name,
    slug: payload?.slug,
    description: payload?.description,
    type: payload?.type,
    price: formatCurrancy(payload?.initiate_price),
    finalPrice: formatCurrancy(payload?.final_price),
    discountPercentage: payload?.discount_percentage,
    createdAt: TimeMapper(payload?.created_at, false),
    updatedAt: TimeMapper(payload?.updated_at, false),
    attachment: payload?.images,
    files: payload?.files?.map((item) => MapFile(item))
  }
}

export const MapFile = (payload) => {
  return {
    id: payload?.id,
    fileAbleId: payload?.fileable_id,
    fileAbleModel: payload?.fileable_model,
    url: payload?.url,
    path: payload?.path,
    createdAt: TimeMapper(payload?.created_at, false),
    updatedAt: TimeMapper(payload?.updated_at, false)
  }
}
